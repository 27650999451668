import React, { useEffect, useState } from "react";
import ReportTable from "../../components/Report/ReportTable";
import CreateReportModal from "../../components/Report/CreateReportModal";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../utils/config";
import { formatDate } from "../../utils/helpers";
import axios from "axios";
import { handleUpdateDraftSlice } from "../../stores/slices/draftSlice";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import { Link, useNavigate } from "react-router-dom";
import { handleCloseCreateReport } from "../../stores/slices/okrSlice";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import DashboardHeader from "../../components/global/DashboardHeader";

const ReportDrafts = () => {
  const navigate = useNavigate();
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      // setTimeout(() => {
      // setLoader(false);
      setState({ run: true, stepIndex: 2 });
      // }, 1200);
    }
  });

  const [draftReports, setDraftReports] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const currentPlan = user?.currentPlan;

  if (currentPlan?.plan_name === "Free" && !currentPlan?.free_trial_status) {
    navigate("/home/billing");
  }

  const handleClose = () => {
    dispatch(handleCloseCreateReport());
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const currentCompany = currentUser?.employees[user?.currentCompany];
      const res = await axios.get(baseURL + "/stats/", {
        params: {
          company_id: currentCompany?.company_id,
          user_id: currentCompany?.user_id,
        },
      });
      setDraftReports(res.data || []);
      dispatch(handleUpdateDraftSlice(res.data || []));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteReport = async (report_id) => {
    try {
      setLoading(true);
      const res = await axios.delete(baseURL + "/stats/", {
        params: {
          report_id,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  return (
    <div className="relative h-screen p-4">
      <DashboardHeader
        dashboardTitle="Reports"
      />
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F0F5FC",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <h1
          className="font-medium text-black mb-3 flex"
          style={{ color: "#0F7BB3", opacity: "80%", fontSize: "28px" }}
        >
          Outstanding Achievements!{" "}
          <span className="flex items-center justify-start">
            <svg
              width="118"
              height="25"
              viewBox="0 0 118 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                strokeWidth: "1.32067",
                verticalAlign: "middle",
              }}
            >
              <path
                d="M1 18.587C5.7027 16.2018 11.4706 17.5503 16.6757 19.5737C43.9868 30.191 32.5107 7.20981 48.027 7.20981C59 7.20981 59.7838 33.0617 74.6757 11.3311C89.5676 -10.3994 80.1622 23.7467 95.0461 23.7467C109.237 23.7467 104.675 7.20981 117 1.58984"
                stroke="url(#paint0_linear_385_2121)"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_385_2121"
                  x1="16.225"
                  y1="18.946"
                  x2="117.186"
                  y2="-14.0898"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FEF7F1" stopOpacity="0" />
                  <stop offset="0.749947" stopColor="#FB4646" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </h1>
        <div className="p-4 h-full bg-white rounded-lg">
          <CustomScrollbar>
            <div className="flex flex-col w-full p-4">
              <span className="flex gap-x-2 items-center">
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="9" height="3" rx="1.5" fill="#0098CA" />
                  <rect y="5" width="11" height="3" rx="1.5" fill="#1AA2CF" />
                  <rect y="10" width="15" height="3" rx="1.5" fill="#0098CA" />
                </svg>
                <p className="text-basic font-medium text-2xl">Reports List</p>
              </span>
              <div className="py-4">
                {!draftReports || loading ? (
                  <div className=" h-full  flex justify-center items-center mx-auto">
                    <img src="/assets/images/Group 3.svg" />
                  </div>
                ) : (
                  <ReportTable
                    draftReports={draftReports}
                    handleDeleteReport={handleDeleteReport}
                  />
                )}
              </div>
            </div>
          </CustomScrollbar>
        </div>

        <CreateReportModal onCancel={handleClose} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default ReportDrafts;
