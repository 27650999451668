import React from "react";
import { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../utils/config";
import axios from "axios";
import { handleOpenCreateGoalModal } from "../stores/slices/okrSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  handleOpenPlanModal,
  handleUpdatePlan,
} from "../stores/slices/userAuthSlice";
import { BiGlobe } from "react-icons/bi";
import { getInitials } from "../utils/helpers";
import DashboardHeader from "../components/global/DashboardHeader";
import EmployeeHeader from "../components/global/EmployeeHeader";
import { Spinner } from "@chakra-ui/react";

const DashboardMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const assign_role = ["user", "admin"];

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const plan = user?.currentPlan;
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [formData, setformData] = useState({
    email: "",
    assign_role: "",
    user: "",
    check_box: "",
  });
  const [teams, setTeams] = useState([]);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setdata] = useState([]);
  const showTeamsModal = () => {
    if (plan) {
      setIsModalOpen(true);
    } else {
      dispatch(handleOpenPlanModal());
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchData = async () => {
    try {
      setLoadingPage(true);
      const currentEmployee = currentUser?.employees;
      let company_id;
      let user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
        user_id = currentEmployee[user?.currentCompany]?.user_id;
      }
      const response = await axios.get(baseURL + "/employee_details/", {
        params: {
          company_id: company_id,
          login_user_id: user_id,
        },
      });
      setdata(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const showModal = () => {
    dispatch(handleOpenCreateGoalModal({ isOpen: true }));
  };

  const handleOk = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setRoleError(false);
    setformData({
      email: "",
      assign_role: "",
      user: "",
      check_box: "",
    });
    toast.dismiss();
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData?.email) {
        return;
      }

      if (formData?.assign_role === "") {
        setRoleError(true);
        return;
      }

      setLoadingInvite(true);

      const res = await axios.post(baseURL + "/send-email/", {
        email: formData?.email,
        role: formData?.assign_role,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        teams: formData?.assign_team,
      });
      setformData({
        email: "",
        assign_role: "",
        user: "",
        check_box: "",
      });
      setLoadingInvite(false);
      handleCancel();
      fetchData();
      getCurrentPlanData();
      navigate("/home/people");
    } catch (error) {
      // console.log(error)
      setLoadingInvite(false);
      if (error?.response?.status == 404) {
        toast.error("Employee already exists in the company", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setformData({
          email: "",
          assign_role: "",
          user: "",
          check_box: "",
        });
      } else {
        toast.error("Invite user failed. Please try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setformData({
          email: "",
          assign_role: "",
          user: "",
          check_box: "",
        });
      }
    } finally {
    }
  };

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await axios.get(baseURL + "/subscription/", {
        params: {
          user_id: currentCompany?.user_id,
          company_id: currentCompany?.company_id,
        },
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "email") {
      setEmailError(!validateEmail(value));
      setformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        user: name === "email" ? value : prevFormData?.user,
      }));
    } else {
      setformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  }

  const getTeams = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await axios.get(baseURL + "/create-team/", {
        params: {
          company_id: company_id,
        },
      });
      setTeams(response?.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      getTeams();
    }
  }, [isModalOpen]);

  return (
    <div className="w-full h-screen p-4 font-poppins ">
      <DashboardHeader dashboardTitle="Dashboard" />
      <div
        className="  flex flex-col p-4"
        style={{
          backgroundColor: "#F0F5FC",
          borderRadius: "16px",
          width: "100%",
          height: "80vh",
        }}
      >
        <EmployeeHeader
          companyName={currentEmployee?.company_name}
          initials={getInitials(currentEmployee?.company_name)}
        />

        <div className="p-4 h-full bg-white rounded-lg grid grid-cols-2 gap-4">
          <div
            className="rounded-lg h-1/2 flex flex-col justify-between p-4"
            style={{
              background: `url("/assets/images/DashboardGoal.png")`,
              backgroundSize: "cover",
              border: "1px solid rgba(126, 126, 133, 0.4)",
            }}
          >
            <div className="flex justify-between">
              <span
                style={{ color: "#063F5D", opacity: "80%" }}
                className="font-medium text-xl w-2/3"
              >
                Develop company and team Objectives and begin tracking their
                progress.
              </span>
              <span>
                <img
                  src="/assets/images/goalMain.png"
                  style={{
                    width: "85px",
                    height: "85px",
                  }}
                />
              </span>
            </div>
            <div>
              <button
                style={{
                  padding: "10px 21px",
                  backgroundColor: "transparent",
                  border: "1px solid #0F7BB3",
                  color: "#0F7BB3",
                  borderRadius: "100px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#094D70";
                  e.target.style.borderColor = "#094D70";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "#0F7BB3";
                  e.target.style.borderColor = "#0F7BB3";
                }}
                onClick={() => {
                  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
                    dispatch(handleOpenPlanModal());
                  } else {
                    showModal();
                  }
                }}
              >
                Create Goals
              </button>
            </div>
          </div>
          <div
            className="rounded-lg h-1/2 flex flex-col justify-between p-4"
            style={{
              background: `url("/assets/images/DashboardTeam.png")`,
              border: "1px solid rgba(126, 126, 133, 0.4)",
              backgroundSize: "cover",
              // Ensure the div takes up full width
            }}
          >
            <div className="flex justify-between">
              <span
                style={{ color: "#063F5D", opacity: "80%" }}
                className="font-medium text-xl w-2/3"
              >
                Include colleagues in executing the strategy as a unified team.
              </span>
              <span>
                <img
                  src="/assets/images/peopleMain.png"
                  style={{
                    width: "85px",
                    height: "85px", // Adjust the height as needed
                  }}
                />
              </span>
            </div>
            <div>
              <button
                style={{
                  padding: "10px 21px",
                  backgroundColor: "transparent",
                  border: "1px solid #0F7BB3",
                  color: "#0F7BB3",
                  borderRadius: "100px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#094D70";
                  e.target.style.borderColor = "#094D70";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "#0F7BB3";
                  e.target.style.borderColor = "#0F7BB3";
                }}
                onClick={() => {
                  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
                    dispatch(handleOpenPlanModal());
                  } else {
                    if (
                      plan?.remaining_team_members > 0 ||
                      plan?.remaining_team_members === -1
                    ) {
                      showTeamsModal();
                    } else {
                      dispatch(handleOpenPlanModal());
                    }
                  }
                }}
              >
                Invite A Teammate
              </button>
            </div>
          </div>
        </div>
        <Modal
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={650}
          footer={null}
        >
          <form className="p-6" onSubmit={handleSubmit}>
            <div className="mb-2">
              <h1 className="p-1 text-3xl font-bold text-black">
                Invite users
              </h1>
              <label className="p-1 text-gray-600">
                Enter one or more email addresses separated by a comma or space.
              </label>
            </div>
            <hr />

            <div className="grid items-center grid-cols-6 py-4 below-div gap-y-4 gap-x-4">
              <label className="col-span-2 font-semibold text-gray-600">
                Email
              </label>
              <div className="col-span-4">
                <input
                  className="w-full  p-4 text-sm border border-gray-300 rounded-lg"
                  type="email"
                  name="email"
                  id="email"
                  onChange={changeHandeler}
                  value={formData.email}
                  placeholder=""
                  required
                />
              </div>
              <label className="col-span-2 font-semibold text-gray-600 ">
                Assign role(s)
              </label>
              <div className="col-span-4">
                <Select
                  className="w-full "
                  placeholder=""
                  name="assign_role"
                  id="assign_role"
                  onChange={(e) => {
                    setRoleError(false);
                    setformData({ ...formData, assign_role: e });
                  }}
                  value={formData.assign_role}
                >
                  {assign_role.map((assign_role, index) => {
                    return (
                      <Select.Option value={assign_role} key={index}>
                        <span className="capitalize">{assign_role}</span>
                      </Select.Option>
                    );
                  })}
                </Select>
                {roleError && (
                  <p className="mt-1 text-red-500">Role is required</p>
                )}
              </div>

              <label className="col-span-2 font-semibold text-gray-600">
                Assign team(s)
              </label>

              <div className="col-span-4 ">
                <Select
                  name="members"
                  id="members"
                  onChange={(val) => {
                    setformData({ ...formData, assign_team: val });
                    // setOwnerError(false)
                  }}
                  value={formData?.assign_team}
                  mode="multiple"
                  required
                  className="w-full "
                  placeholder="Select Member"
                >
                  {teams?.map((team, index) => {
                    return (
                      <Select.Option
                        value={team?.team_id || team?.tema_id}
                        key={index}
                      >
                        {/* {team?.username} */}
                        <div className="flex items-center gap-x-2">
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                          <span>{team?.team_name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div className="flex items-center justify-end col-span-6 gap-x-4">
                <button
                  className="px-4 py-2 border rounded-xl border-black hover:text-blue-500 hover:border-blue-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border rounded-xl text-white bg-button hover:bg-button-hover"
                  disabled={loadingInvite}
                >
                  Invite User
                  {loadingInvite && (
                    <Spinner size="sm" color="white" className="ml-2" />
                  )}
                </button>
              </div>
            </div>
            {/* )} */}
            <ToastContainer />
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default DashboardMain;
