import React, { useEffect } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import { Sessions } from "../../utils/Constants";
import { handleUpdateSession } from "../../stores/slices/okrSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../StepByStepGuide/context";

const SwitchSubController = () => {
  const {
    setState,
    state: { run },
  } = useAppContext();


  useEffect(() => {
    const showstep = localStorage.getItem("startTour");
    if (showstep) {
      setState({ run: true, tourActive: true });
    }
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const okrSlice = useSelector((state) => state?.okr);
  const dispatch = useDispatch();

  if (location.pathname.includes("/home/dashboard")) {
    return (
      <div
        className="flex flex-col items-center justify-center pr-3 pl-3"
        style={{
          backgroundColor: "#F0F5FC",
        }}
      >
        
        {run && (
          <button
            className="px-5 py-1.5 my-4 text-white bg-button hover:shadow-lg first_tour"
            onClick={() => navigate("/home/sessions")}
          >
            Create Goal
          </button>
        )}
      </div>
    );
  } else if (location.pathname.includes("/home/sessions"))
    return (
      <ul className="flex flex-col w-full gap-1 pb-5 pt-5 pl-5 pr-5">
        {Sessions.map((session, index) => (
          <li
            key={session.value}
            className={`w-full flex gap-1 items-center justify-center text-center pl-2 cursor-pointer transition-all  p-1 text-base font-medium  rounded
             `}
            style={{
                    backgroundColor: session.value === okrSlice?.session ? "#FEF5E3" : "",
                    color: session.value === okrSlice?.session ? "#204579" : "text-gray-700",
                    fontWeight : session.value === okrSlice?.session ? "400" : "500",
                    border: session.value === okrSlice?.session ? "0.5px solid #FEF5E3" : "0.5px solid rgba(126, 126, 133, 0.4)"
                  }}
            
            onClick={() => {
              dispatch(handleUpdateSession(session.value));
              navigate(`/home/sessions?session=${session.value}`);
            }}
          >
            {session.label}
          </li>
        ))}
      </ul>
    );

};

export default SwitchSubController;
