import React, { useEffect, useRef, useState } from "react";
import { Navroutes } from "../../utils/Navroutes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateSession } from "../../stores/slices/okrSlice";
import SwitchSubController from "./SwitchSubController";
import { Sessions } from "../../utils/Constants";
import { handleOpenPlanModal } from "../../stores/slices/userAuthSlice";
import { baseURL } from "../../utils/config";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userold = useSelector((state) => state?.user);
  const currentUser = userold?.currentUser;
  const [loading, setLoading] = useState(false);
  const currentEmployee = currentUser?.employees[userold?.currentCompany];
  const currentPlan = userold?.currentPlan;
  const okrSlice = useSelector((state) => state?.okr);

  const pathname = location.pathname;
  const [activeRoute, setActiveRoute] = useState();
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const plan = userold?.currentPlan;

  const upgradePlan = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("user_id", currentPlan?.user_id);
      const res = await axios.post(
        `${baseURL}/create-customer-portal-session/`,
        {
          user_id: currentPlan?.user_id,
        }
      );
      const data = res.data;
      window.location.href = data.url;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pathname?.split("/").length > 2) {
      setActiveRoute(Navroutes.find((route) => pathname?.includes(route.path)));
    }
  }, [pathname]);

  // #063F5D
  // #43B7DE
  // #FFB319

  return (
    <div
      className={`flex h-screen sticky text-black w-80`}
      style={{
        width: "270px",
        boxShadow: "1px 0px 10.399999618530273px 0px #C9E0FF",
        overflow: "hidden",
      }}
      // style={{ backgroundColor: "#43B7DE" }}
    >
      <div
        className="overflow-y-auto overflow-x-hidden box-content "
        style={{ marginRight: "-20px" }}
      >
        <nav
          className="flex flex-col items-start justify-between h-full col-span-1 w-full "
          // style={{ backgroundColor: "#063F5D" }}
        >
          <ul className="flex flex-col items-center justify-center w-full mt-2 ">
            <li className="flex items-center justify-center w-full p-2 mb-12">
              <div className="">
                <img
                  onClick={() => {
                    navigate("/home/dashboard");
                  }}
                  src={"/assets/images/imongu2.svg"}
                  // className='w-5 h-5 text-white mix-blend-multiply cursor- '
                  className="w-26 h-26"
                  alt=""
                  srcSet=""
                />
              </div>
              {/* <img src={logoImage} className='w-5 h-5 mix-blend-multiply ' alt="" srcSet="" /> */}
            </li>
            {Navroutes?.map((route, index) => {
              return (
                <MenuItem
                  key={index}
                  index={index}
                  activeRoute={activeRoute}
                  children={route.icon}
                  setActiveRoute={setActiveRoute}
                  route={route}
                  dispatch={dispatch}
                  Sessions={Sessions}
                  okrSlice={okrSlice}
                  pathname={pathname}
                  plan={plan}
                />
              );
            })}
            <div className="flex w-full px-2 items-center m-2">
              {plan?.free_trial_status && (
                <div
                  className="font-medium px-2 w-full text-black bg-white flex p-2"
                  style={{
                    border: "1px #FFF4E9 solid",
                    borderRadius: "15px",
                    backgroundColor: "#FEF5E3",
                  }}
                >
                  <div className="flex justify-center items-center  gap-x-2  select-option-owner">
                    <span className="flex flex-col items-center justify-between gap-y-2 ">
                      <p
                        className="items-center px-3 font-poppins text-sm"
                        style={{
                          color: "#0B0B0B",
                        }}
                      >
                        Your free trial expires in {plan?.remaining_days}{" "}
                        {plan?.remaining_days === 1 ? "day." : "days."}
                      </p>
                      <button
                        className="font-medium flex text-sm items-center gap-x-2 bg-button hover:bg-button-hover"
                        disabled={loading}
                        style={{
                          padding: "5px 12px",
                          width: "130px",
                          borderRadius: "30px",
                          color: "#fff",
                          cursor: loading ? "not-allowed" : "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={upgradePlan}
                      >
                        {loading && (
                          <>
                            <Spinner
                              bg={"transparent"}
                              size="xs"
                              color="white.500"
                            />
                          </>
                        )}
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1765_543)">
                            <path
                              d="M7.5001 0.643066L7.3419 0.761133C5.49326 2.15068 3.67393 3.06153 0.895996 3.52442L0.67627 3.55957V3.78223C0.67627 4.33887 0.922363 5.20899 1.32373 6.27539C1.72217 7.34473 2.28467 8.58399 2.93565 9.76758C3.58897 10.9512 4.32432 12.0791 5.09776 12.9229C5.86241 13.7695 6.66514 14.3555 7.5001 14.3555C8.33506 14.3555 9.1378 13.7695 9.90831 12.9229C10.6759 12.0791 11.4112 10.9512 12.0675 9.76758C12.7179 8.58399 13.2774 7.34473 13.6788 6.27539C14.0773 5.20899 14.3233 4.33887 14.3233 3.78223V3.55957L14.1066 3.52442C11.3351 3.06153 9.50987 2.15068 7.65831 0.761133L7.5001 0.643066ZM7.5001 1.29346C9.29014 2.61299 11.1446 3.52442 13.7814 3.99903C13.7257 4.44434 13.5177 5.21192 13.1866 6.09375C13.0665 6.41016 12.9347 6.74414 12.7853 7.08985H8.4376V5.65723L10.3126 6.12598L7.5001 2.8459L4.6876 6.12598L6.5626 5.65723V7.08985H2.21406C2.06758 6.74414 1.93311 6.41016 1.81299 6.09375C1.48486 5.21192 1.27393 4.44434 1.21826 3.99903C3.85557 3.52442 5.70713 2.61299 7.5001 1.29346Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1765_543">
                              <rect width="15" height="15" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Upgrade
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;

const MenuItem = ({
  index,
  activeRoute,
  children,
  setActiveRoute,
  route,
  dispatch,
  Sessions,
  okrSlice,
  pathname,
  plan,
  // clicked,
  // setClicked,
}) => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(true);
  const handleIconClick = () => {
    if (
      !plan?.features?.Integration &&
      route.name === "Integrations" &&
      !(plan?.plan_name === "Free") &&
      !plan?.free_trial_status
    ) {
      dispatch(handleOpenPlanModal());
      return null;
    }

    if (
      !plan?.features?.Strategic_insights &&
      route.name === "Insight" &&
      !(plan?.plan_name === "Free") && // Corrected condition with parentheses
      !plan?.free_trial_status
    ) {
      dispatch(handleOpenPlanModal());
      return null;
    }

    setClicked(!clicked);
  };

  const handleMenuItemClick = (routePath) => {
    if (
      !plan?.features?.Integration &&
      route.name === "Integrations" &&
      !(plan?.plan_name === "Free") &&
      !plan?.free_trial_status
    ) {
      dispatch(handleOpenPlanModal());
      return null;
    }

    if (
      !plan?.features?.Strategic_insights &&
      route.name === "Insight" &&
      !(plan?.plan_name === "Free") &&
      !plan?.free_trial_status
    ) {
      dispatch(handleOpenPlanModal());
      return null;
    }

    if (routePath === "/home/sessions") {
      dispatch(handleUpdateSession());
    }
    setActiveRoute(Navroutes[index]);
    navigate(routePath);
  };

  return (
    <li className="w-full flex flex-col">
      <div
        className={`w-full text-primary grid grid-cols-4 items-center justify-around py-4 px-2 m-0.5 group/link cursor-pointer }`}
        style={{
          backgroundImage:
            activeRoute?.key === index
              ? "linear-gradient(89.87deg, rgba(247, 203, 115, 0.6) -0.78%, rgba(250, 224, 170, 0.09) 98.41%)"
              : "",
        }}
        onClick={() => {
          if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
            dispatch(handleOpenPlanModal());
          } else {
            handleMenuItemClick(route.path);
          }
        }}
      >
        {/* <Tooltip title={route.name} placement="right"> */}
        <p className=" col-span-1 ml-3">
          {React.cloneElement(children, {
            style: {
              ...children.props.style,
              fill: activeRoute?.key === index ? "#FFA043" : "#9398AD",
            },
          })}
        </p>
        {/* </Tooltip> */}
        <span
          className={`col-span-2 ml-3`}
          style={{
            color: activeRoute?.key === index ? "#063F5D" : "#7E7E85",
            fontWeight: activeRoute?.key === index ? "600" : "500",
          }}
          onClick={() => {
            if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
              dispatch(handleOpenPlanModal());
            } else {
              handleIconClick();
            }
          }}
        >
          {route.name}
        </span>
        <div
          key={index}
          className=" col-span-1 "
          onClick={() => {
            if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
              dispatch(handleOpenPlanModal());
            } else {
              handleIconClick();
            }
          }}
        >
          {activeRoute?.key === index && clicked ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#204579"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 9L7 14H17L12 9Z" fill="#204579" />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#204579"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 15L7 10H17L12 15Z" fill="#204579" />
            </svg>
          )}
        </div>
      </div>

      {activeRoute?.key === index && clicked && (
        <div
          className="w-full flex flex-col justify-center items-center pt-2 pl-5 pr-5 pb-2"
          style={{
            backgroundColor: "#FEFCF6",
          }}
        >
          <ul className="flex flex-col w-full gap-1 pb-1">
            {activeRoute?.key === index &&
              activeRoute?.subroutes?.map((route, index) => (
                <li
                  key={index}
                  className={`w-full flex gap-x-2 rounded items-center cursor-pointer text-center justify-center  py-3 rounded-l 
                  `}
                  style={{
                    backgroundColor: route?.path === pathname ? "#FEF5E3" : "",
                    color: route?.path === pathname ? "#204579" : "",
                    fontWeight: route?.path === pathname ? "400" : "500",
                    border:
                      route?.path === pathname
                        ? "0.5px solid #FEF5E3"
                        : "0.5px solid rgba(126, 126, 133, 0.4)",
                  }}
                  onClick={() => {
                    if (pathname?.includes("/home/sessions")) {
                      dispatch(handleUpdateSession());
                    }
                    navigate(route.path);
                  }}
                >
                  {React.cloneElement(route.icon, {
                    style: {
                      ...route?.icon.props.style,
                      fill: route?.path === pathname ? "#204579" : "#7E7E85",
                    },
                  })}
                  {route?.name}
                  {route?.path === "/home/reports" && (
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 7.5L15.5 12.5L10.5 17.5"
                        stroke="#204579"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </li>
              ))}
          </ul>
          <SwitchSubController />
        </div>
      )}
    </li>
  );
};
