import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateEmailVerification, updateUserSignup } from "../stores/slices/userAuthSlice";
import { Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { baseURL } from "../utils/config";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [verification, setVerification] = useState(false);
  const email = useSelector((state) => state.user?.email);
  const user = useSelector((state) => state?.user);
  const emailverification = useSelector((state) => state?.user?.emailverified);
  const searchParams = new URLSearchParams(location.search);
  const emailToken = searchParams.get("verify_token");
  const userId = searchParams.get("user_id");


  useEffect(() => {
    if(user && emailverification){
      navigate(`/home/dashboard`); 
    }
  }, [user,emailverification])

  
  const handleResendVerificationEmail = async () => {
    try {
      const response = await fetch(baseURL + "/verify_email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();
      if(data.message){
      toast.warn(data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
  }

      // Set verification to true after successful resend
      setVerification(true);
    } catch (err) {
      toast({
        title: "An error occurred. Please try again later.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  const fetchData = async () => {
    try {
      if (emailToken && userId && !verification) {
        const response = await fetch(baseURL + "/verify_token/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ verify_token: emailToken, user_id: userId }),
        });

        const data = await response.json();

        if (data && !data?.error) {
          setVerification(true);
          dispatch(updateUserSignup(data));
          dispatch(updateEmailVerification(true));
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              'event': 'sign_up',
              'message': 'Sign Up Successful',
              'user_name': data?.username,
              'user_email': data?.email,
              'event_ts': new Date(Date.now()).toLocaleString(),
          });
          navigate(`/home/dashboard`);
        }
      }
    } catch (err) {
      toast({
        title: "An error occurred. Please try again later.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (emailToken && userId && !verification) {
      fetchData();
    }
  }, []);

  return (
    <div className="h-screen flex justify-center items-center flex-col p-4">
      {emailToken && userId ? (
        <div>
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="w-full md:w-96">
            <h1 className="font-bold text-center mb-4">Check Your Email</h1>

            <p className="text-md text-center mb-4 font-semibold opacity-60">
              Account created successfully 🎉. Please check your email to
              confirm your account. You might need to check your spam folder if
              you don’t see it.
            </p>

            <p className="text-md text-center mb-4 text-gray-800 opacity-60 font-medium">
              If you do not receive the email within the next 5 minutes, click
              the link below to resend verification email.
            </p>

            <p
              className="text-orange-500 text-center text-md font-semibold hover:underline hover:text-orange-700 cursor-pointer"
              onClick={handleResendVerificationEmail}
            >
              Resend verification email
            </p>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default EmailVerification;
