import React from "react";
import SendIcon from "@mui/icons-material/Send";

const ChatInput = ({
  handleEnterKeyPress,
  handleQueries,
  query,
  loading,
  handleSendMessage,
  openModal
}) => {
  return (
    <>
      <div
        className="border border-t"
        style={{
          height: "8vh",
          display: "flex",
          alignItems: "center",
          padding: "8px",
        }}
      >
        <input
          type="text"
          placeholder="Type your message..."
          className="w-10/12"
          onKeyDown={handleEnterKeyPress}
          onChange={handleQueries}
          value={query}
          disabled={loading || openModal}
          style={{
            padding: "0.5rem",
            marginRight: "1rem",
            marginLeft: '1px',
            backgroundColor: "#EFEFEF",
            border: "none",
            borderRadius: "4px",
            // color: 'rgba(24, 28, 30, 0.47)',
            flex: "1",
          }}
        />
        <button
          onClick={handleSendMessage}
          disabled={loading || openModal || !query}
          style={{
            padding: "0.5rem",
            color: "#063F5D",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <SendIcon />
        </button>
      </div>
    </>
  );
};

export default ChatInput;
