import React, { useEffect } from "react";
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import Sidebar from "../../components/global/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../utils/config";
import {
  handleClosePlanModal,
  handleOpenPlanModal,
  handleUpdatePlan,
} from "../../stores/slices/userAuthSlice";
import axios from "axios";
import { AppProvider } from "../../components/StepByStepGuide/context";
import MultiRouteWrapper from "../../components/StepByStepGuide/MultiRouteWrapper";
import GoalDrawerComponent from "../../components/Okrs/Goals/GoalDrawerComponent";
import { useState } from "react";
import DrawerContainer from "../../components/Okrs/Drawer";
import SearchAndButton from "../../components/global/SearchAndButton";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MdChatBubbleOutline } from "react-icons/md";
import ChatBotComponent from "../../components/global/ChatBotComponent";
import PricingModal from "../../components/global/PricingModal";
import { Modal } from "antd";

const Dashboard = () => {
  const location = useLocation();
  const matchGoalRoute = useMatch("/home/sessions/:goalid");
  const matchReportRoute = useMatch("/home/reports/:reportid");
  const matchRoute = useMatch("/home/sessions");
  const user = useSelector((state) => state?.user);
  const plan = user?.currentPlan;
  const [goalData, setGoalData] = useState();
  const [okrData, setOkrData] = useState();
  const [drawerData, setDrawerData] = useState();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const okrSlice = useSelector((state) => state.okr);
  const [keyResultDrawerData, setKeyResultDrawerData] = useState();
  const dispatch = useDispatch();
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const { goalid } = useParams();
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleToggleDrawer = (data) => {
    setOpenDrawer(!openDrawer);
  };

  const handleChatbotButton = () => {
    if (!plan?.free_trial_status && !plan?.features?.AI_Chatbot) {
      dispatch(handleOpenPlanModal());
    } else {
      setChatbotOpen(!isChatbotOpen);
    }
  };

  useEffect(() => {
    dispatch(handleClosePlanModal());
  }, [location, dispatch]);

  const getOkrData = async (okr_id) => {
    try {
      const res = await axios.get(baseURL + "/okr/", {
        params: {
          okr_id: okr_id,
        },
      });
      setDrawerData(res.data?.children[0]);
      //  setGoalOkrData(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (okrSlice?.openOkrDrawer) {
      getOkrData(okrSlice?.openOkrDrawer);
    }
  }, [okrSlice?.openOkrDrawer]);

  const getOkrs = async (goal_id) => {
    if (goal_id) {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const result = await axios.get(baseURL + "/owners/", {
          params: {
            goal_id: goal_id,
          },
        });
        // return
        if (result?.data?.parent) {
          setGoalData(result?.data?.children[0]);
          setOkrData(result?.data);
        } else {
          setGoalData(result?.data?.children[0]);
          setOkrData(result.data?.children[0]);
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          setGoalData([]);
          setOkrData([]);
        }
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (okrSlice?.openGoalDrawer || goalid) {
      getOkrs(okrSlice?.openGoalDrawer || goalid);
    }
  }, [okrSlice?.openGoalDrawer, goalid]);

  useEffect(() => {
    if (user && user?.authenticated !== true) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    setChatbotOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const getCurrentPlanData = async () => {
      try {
        const currentCompany = currentUser?.employees[user?.currentCompany];
        const result = await axios.get(baseURL + "/subscription/", {
          params: {
            user_id: currentCompany?.user_id,
            company_id: currentCompany?.company_id,
          },
        });
        dispatch(handleUpdatePlan(result.data));
      } catch (err) {
        console.log(err);
      }
    };
    getCurrentPlanData();
  }, []);

  return (
    <div className="w-full h-screen fixed flex font-poppins overflow-y-hidden cursor-default">
      <AppProvider>
        <MultiRouteWrapper />
        <Sidebar />
        <div className="w-full">
          <SearchAndButton />
          <div className="mt-20">
          <Outlet />
          <Modal
            open={user?.PlanModal}
            onCancel={() => {
              dispatch(handleClosePlanModal());
            }}
            width={"80%"}
            className="p-0"
            footer={null}
          >
            <div className="flex justify-center items-center pl-0">
            <PricingModal widthNew="true" />
            </div>
          </Modal>
          {!matchGoalRoute && !matchReportRoute && (
            <>
              <GoalDrawerComponent
                drawerData={goalData}
                okrData={okrData}
                getOkrs={getOkrs}
              />
              <DrawerContainer
                openDrawer={openDrawer}
                handleToggleDrawer={handleToggleDrawer}
                drawerData={drawerData || okrSlice?.currentOkrData}
                keyResultDrawerData={keyResultDrawerData}
                setKeyResultDrawerData={setKeyResultDrawerData}
                getOkrData={getOkrData}
                getOkrs={getOkrs}
              />
            </>
          )}
          {!matchRoute && !matchGoalRoute && (
            <div className="z-auto flex flex-col absolute bottom-5 right-5 p-2">
              <Popover
                isLazy
                placement="top-end"
                isOpen={isChatbotOpen}
                onClose={handleChatbotButton}
              >
                <PopoverTrigger>
                  <div className="flex flex-col justify-end items-end">
                    {!isChatbotOpen && (
                      <div
                        className=" mr-5 mb-2 text-sm shadow-sm right-10 rounded-t-md rounded-bl-md p-3"
                        style={{ backgroundColor: "#FFD580" }}
                      >
                        Hi 👋! I'm Joyce, your chatbot assistant. How may I help
                        you?
                      </div>
                    )}
                    <Button
                      p={2}
                      borderRadius="full"
                      backgroundColor="#063F5D"
                      color="white"
                      _hover={{ padding: "4px", bg: "#042a45" }}
                      onClick={handleChatbotButton}
                      className="relative flex justify-end"
                    >
                      <span className="flex justify-end items-center gap-x-2">
                        {isChatbotOpen ? (
                          <KeyboardArrowDownIcon className="text-white" />
                        ) : (
                          <MdChatBubbleOutline className="text-white" />
                        )}
                      </span>
                    </Button>
                  </div>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    borderColor="white"
                    className="mr-5 text-sm rounded-t-md rounded-bl-md"
                    style={{
                      width: "35vw",
                      minWidth: "500px",
                    }}
                  >
                    <div
                      style={{
                        transition:
                          "width 1s cubic-bezier(0.42, 0, 0.58, 1), height 1s cubic-bezier(0.42, 0, 0.58, 1)",
                      }}
                    >
                      <ChatBotComponent handleClose={handleChatbotButton} />
                    </div>
                  </PopoverContent>
                </Portal>
              </Popover>
            </div>
          )}
          </div>
        </div>
      </AppProvider>
    </div>
  );
};

export default Dashboard;
