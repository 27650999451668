import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { GoGoal } from "react-icons/go";
import { MdGroups } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAvatarColor, getInitials } from "../utils/helpers";
import {
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
} from "../stores/slices/okrSlice";
import MentionInput from "./Okrs/Comments/CustomMentionInput";
import axios from "axios";
import { baseURL } from "../utils/config";

const NotificationModal = ({
  handleNotificationOpen,
  data,
  divRef,
  handleNotificationClick,
  deleteAllnotification,
  handleDeleteNotification,
  count,
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = user?.currentUser;
  const [showDivIndex, setShowDivIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [mention, setMentions] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);

  const handleCloseButtonClick = () => {
    handleNotificationOpen();
  };

  const handleItemClick = (item) => {
    try {
      const { type, changes } = item;

      if (changes?.goal_id) {
        dispatch(handleOpenGoalDrawer(changes?.goal_id));
        handleCloseButtonClick();
      } else if (changes?.okr_id) {
        dispatch(handleOpenOkrDrawer(changes?.okr_id));
        handleCloseButtonClick();
      } else if (changes?.key_id) {
        dispatch(handleOpenKeyresultDrawer(changes?.key_id));
        handleCloseButtonClick();
      } else if (type === "team") {
        handleCloseButtonClick();
        navigate("/home/people/teams");
      } else {
        throw new Error("Invalid item type.");
      }
    } catch (error) {
      console.error("Error in handleItemClick:", error);
    }
  };

  const OpenModal = ({ index, item }) => {
    if (showDivIndex === index) {
      return setShowDivIndex(null);
    }
    setShowDivIndex(index);
    setSelectedItem(item);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = `${date.getDate()}th ${getMonthName(
      date.getMonth()
    )} ${date.getFullYear()}`;
    return formattedDate;
  }

  const handleDeleteButtonClick = (item) => {
    setNotificationToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteNotification = () => {
    handleDeleteNotification(notificationToDelete.id);
    setIsDeleteModalOpen(false);
    setNotificationToDelete(null);
  };

  const cancelDeleteNotification = () => {
    setIsDeleteModalOpen(false);
    setNotificationToDelete(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/assignowners/", {
          params: {
            company_id: company_id,
          },
        });
        setMentions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  function getMonthName(monthIndex) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  }

  const conditionVariable = false;
  //  !selectedItem?.isDeleted ||
  // (!(selectedItem?.changes?.userOwners?.oldValue?.length === 0) &&
  // !(selectedItem?.changes?.teamOwners?.oldValue?.length === 0))

  return (
    <div
      className="text-black absolute bg-white z-50 top-0 right-0 shadow-md rounded-md overflow-hidden"
      // onClick={handleModalClick}
      style={{
        width: "50vw",
        height: "90vh",
      }}
      // ref={divRef}
    >
      <div className="p-4 flex flex-col justify-between shadow-lg w-full">
        <div className="flex justify-between items-center ">
          <p className="font-bold text-2xl">Notification</p>
          <span className="flex justify-evenly items-center gap-x-3">
            <span
              className="close"
              onClick={() => {
                handleCloseButtonClick();
              }}
              style={{
                cursor: "pointer",
                color: "inherit",
                transition: "background-color 0.3s, color 0.3s",
                width: "15px",
                height: "15px",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#ffdddd";
                e.target.style.color = "#8b0000";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "inherit";
              }}
            >
              <IoMdClose />
            </span>
          </span>
        </div>
        {data.length > 0 && (
          <div>
            <div className="text-sm">
              You have <span className="text-blue-500 ">{count} unread </span>
              notifications.
            </div>

            <div
              className="flex justify-end text-sm cursor-pointer"
              onClick={deleteAllnotification}
              style={{
                color: "#71788D",
                fontWeight: "400",
                transition: "color 0.3s, font-weight 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#094D70";
                e.target.style.fontWeight = "500";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#71788D";
                e.target.style.fontWeight = "400";
              }}
            >
              Clear All
            </div>
          </div>
        )}
      </div>
      {data.length ? (
        <div
          className="m-3 overflow-y-scroll  "
          style={{
            height: "72vh",
          }}
        >
          {data?.map((item, index) => (
            <div
              className="w-full my-2"
              style={{
                backgroundColor: !item.is_seen ? "#F3F8FE" : "",
                border: "1px solid rgba(72, 88, 133, 0.2)",
              }}
              key={index}
            >
              <div
                className={`grid grid-cols-6 rounded py-2 w-full`}
                onClick={() => {
                  handleNotificationClick({ index, item });
                  OpenModal({ index, item });
                }}
              >
                {/* Avatar */}
                <div className="col-span-3 sm:col-span-1 flex items-center justify-center">
                  {!item.is_seen && (
                    <div
                      style={{ backgroundColor: "#FF0D73" }}
                      className="transform -translate-x-1/2 -translate-y-1/2 h-2 w-2 rounded-full"
                    ></div>
                  )}
                  <div
                    className="w-8 h-8 border text-sm border-black rounded-full flex items-center justify-center text-white relative"
                    style={{
                      backgroundColor: item?.profile_image
                        ? "initial"
                        : getAvatarColor(item.username),
                    }}
                    size={8}
                  >
                    {item?.profile_image ? (
                      <img
                        src={item.profile_image}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                          display: "block",
                        }}
                      />
                    ) : (
                      getInitials(item?.username)
                    )}
                  </div>
                </div>
                {/* Notification Content */}
                <div className="col-span-3 sm:col-span-4 flex flex-col gap-2">
                  <div className="flex gap-x-2">
                    <p>{item?.notification}</p>
                    <div className="flex items-center">
                      {showDivIndex === index ? (
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 -0.0015626L0 5.99844L1.4 7.39844L6 2.79844L10.6 7.39844L12 5.99844L6 -0.0015626Z"
                            fill="#094D70"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
                            fill="#A3A3A3"
                          />
                        </svg>
                      )}
                    </div>
                  </div>

                  {showDivIndex === index && selectedItem?.changes && (
                    <div>
                      {/* New Owners */}
                      <div className="flex gap-1">
                        {conditionVariable && <div>To: </div>}
                        {selectedItem?.changes?.userOwners?.newValue?.map(
                          (owner, index) => (
                            <div key={index} className="flex gap-1">
                              <div
                                className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                style={{
                                  backgroundColor: owner?.profile_image
                                    ? "initial"
                                    : getAvatarColor(owner.username),
                                }}
                                size={8}
                              >
                                {owner?.profile_image ? (
                                  <img
                                    src={owner?.profile_image}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  getInitials(owner.username)
                                )}
                              </div>
                              <div>{owner.username}</div>
                            </div>
                          )
                        )}
                        {/* Render New Team Owners */}
                        {selectedItem?.changes?.teamOwners?.newValue?.map(
                          (owner, index) => (
                            <div
                              key={index}
                              className="flex flex-row gap-1 items-center"
                            >
                              <span
                                className="p-0.5 rounded-full flex items-center justify-center"
                                style={{ backgroundColor: "#063F5D" }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #fff",
                                    borderRadius: "100vh",
                                    padding: "2px",
                                  }}
                                >
                                  <MdGroups className="text-white" size={10} />
                                </div>
                              </span>
                              <div>{owner.team_name}</div>
                            </div>
                          )
                        )}
                      </div>
                      {selectedItem?.isDeleted && (
                        <div className="flex gap-1">
                          <p className="font-bold">Owners: </p>
                          {selectedItem?.changes?.userOwners?.oldValue?.map(
                            (owner, index) => (
                              <div key={index} className="flex gap-1">
                                <div
                                  className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                  style={{
                                    backgroundColor: owner?.profile_image
                                      ? "initial"
                                      : getAvatarColor(owner.username),
                                  }}
                                  size={8}
                                >
                                  {owner?.profile_image ? (
                                    <img
                                      src={owner?.profile_image}
                                      alt="Profile"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                        display: "block",
                                      }}
                                    />
                                  ) : (
                                    getInitials(owner.username)
                                  )}
                                </div>
                                <div>{owner.username}</div>
                              </div>
                            )
                          )}
                          {selectedItem?.changes?.teamOwners?.oldValue?.map(
                            (owner, index) => (
                              <div
                                key={index}
                                className="flex flex-row gap-1 items-center"
                              >
                                <span
                                  className="p-0.5 rounded-full flex items-center justify-center"
                                  style={{ backgroundColor: "#063F5D" }}
                                >
                                  <div
                                    style={{
                                      border: "1px solid #fff",
                                      borderRadius: "100vh",
                                      padding: "2px",
                                    }}
                                  >
                                    <MdGroups
                                      className="text-white"
                                      size={10}
                                    />
                                  </div>
                                </span>
                                <div>{owner.team_name}</div>
                              </div>
                            )
                          )}
                        </div>
                      )}

                      {/* Title Change */}
                      {selectedItem?.changes?.title?.newValue && (
                        <div className="flex gap-1">
                          <p className="font-bold">Title:</p>
                          <div className="flex">
                            <p>From : </p>
                            <p className="text-blue-500 ml-1">
                              {selectedItem?.changes?.title?.oldValue}
                            </p>
                          </div>
                          <p className="flex">
                            To :
                            <p className="text-blue-500 ml-1">
                              {selectedItem?.changes?.title?.newValue}
                            </p>
                          </p>
                        </div>
                      )}
                      {selectedItem?.changes?.description?.newValue && (
                        <div className="flex gap-1">
                          <p className="font-bold">Description:</p>
                          <p className="flex">
                            <p>From : </p>
                            <p className="text-blue-500 ml-1">
                              {selectedItem?.changes?.description?.oldValue}
                            </p>
                          </p>
                          <p className="flex">
                            To :
                            <p className="text-blue-500 ml-1">
                              {selectedItem?.changes?.description?.newValue}
                            </p>
                          </p>
                        </div>
                      )}
                      {selectedItem?.changes?.text && (
                        <div className="flex gap-1">
                          <p className="font-bold">Comment:</p>
                          <MentionInput
                            editState={selectedItem?.changes?.text}
                            readOnly="readOnly"
                            className="text-blue-500"
                            mention={mention}
                            setMentions={setMentions}
                          />
                          <hr />
                        </div>
                      )}
                    </div>
                  )}
                  <p>{formatDate(item?.date_created)}</p>
                </div>
                {/* Date Created */}
                <div className="col-span-3 sm:col-span-1 text-gray-500 flex justify-center items-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteButtonClick(item);
                    }}
                    className="bg-white transition-all rounded-lg"
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: "color 0.2s ease-in-out",
                      }}
                      className="hover:text-red-500"
                    >
                      <path
                        d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {showDivIndex === index && (
                <div className=" w-full my-2 flex justify-center items-center">
                  <div className="w-11/12 flex justify-between">
                    <p className="flex gap-2">
                      {item?.type === "objective" ? (
                        <button>
                          <img
                            src="/assets/images/objective.svg"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </button>
                      ) : item?.type === "goal" ? (
                        <button
                          className="rounded p-1"
                          style={{
                            background:
                              "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                          }}
                        >
                          <GoGoal
                            className="text-md"
                            style={{
                              color: "#B91C1C",
                              width: "18px",
                              height: "18px",
                            }}
                          />
                        </button>
                      ) : item?.type === "team" ? (
                        <span
                          className="p-0.5 rounded-full"
                          style={{ backgroundColor: "#063F5D" }}
                        >
                          <div
                            style={{
                              border: "1px solid #fff",
                              borderRadius: "100vh",
                              padding: "3px",
                            }}
                          >
                            <MdGroups className="text-white" size={15} />
                          </div>
                        </span>
                      ) : (
                        <button>
                          <img
                            src="/assets/images/keyresults.svg"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </button>
                      )}
                      <p>{item?.title}</p>
                    </p>
                    <div>
                      {!selectedItem?.isDeleted && (
                        <div
                          className="cursor-pointer cols-span-1 text-center underline underline-offset-2 text-blue-700"
                          onClick={(event) => {
                            handleItemClick(selectedItem);
                          }}
                        >
                          View {selectedItem?.type}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {isDeleteModalOpen && (
            <div
              className="absolute top-28 left-16 items-center justify-center bg-opacity-50 z-50 shadow-md"
              onClick={cancelDeleteNotification}
            >
              <div
                className="bg-white p-5 rounded shadow-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="text-lg mb-4">
                  Are you sure you want to delete this notification?
                </p>
                <div className="flex justify-end gap-4">
                  <button
                    className="px-4 py-2 bg-gray-200 rounded"
                    onClick={cancelDeleteNotification}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded"
                    onClick={confirmDeleteNotification}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="p-4 flex justify-center h-full items-center text-gray-500">
          <div className="flex flex-col">
            <img
              src="/assets/images/NoNotification.png"
              className="w-80 h-80"
            />
            <div className="flex flex-col">
              <p
                style={{
                  color: "#434E6C",
                  fontSize: "32px",
                }}
              >
                No Notifications Here.
              </p>
              <p style={{ color: "#9CA8C9", textAlign: "center" }}>
                There are currently no notifications to display
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationModal;
