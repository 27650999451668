import { useState, useEffect, useRef } from "react";
import { Flex, Modal, Progress, Spin } from "antd";

import { GoGoal } from "react-icons/go";

import { BiRadioCircleMarked, BiSolidBarChartAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  getDaysDifferenceFromDate,
  getNameBySessionId,
  formatDate,
} from "../../../utils/helpers";
import {
  handleOpenCreateGoalModal,
  handleOpenCreateOkrModal,
  handleOpenOkrDrawer,
} from "../../../stores/slices/okrSlice";
import { FaRegStopCircle } from "react-icons/fa";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  filter,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import FilterComponent from "../../global/FilterComponent";
import CustomScrollbar from "../../global/CustomScrollBar";
import { MdChatBubbleOutline } from "react-icons/md";
import ChatBotComponent from "../../global/ChatBotComponent";
import { handleOpenPlanModal } from "../../../stores/slices/userAuthSlice";

const TableView = ({ goals, goalsLoading, formData, setformData, owners }) => {
  const [toggleData, setToggleData] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [selectedgoal, setSelectedGoal] = useState(null);
  const [summarize, setSummarize] = useState(false);
  const navigate = useNavigate();
  const okrSlice = useSelector((state) => state.okr);
  const plan = useSelector((state) => state?.user?.currentPlan);

  const handleFilter = () => {
    setFilterModal(!filterModal);
  };

  const [visibleFields, setVisibleFields] = useState(["Session"]);

  const values = [
    "Session",
    "Goal Owners",
    "Goal Name",
    "Objective Owners",
    "Objective Name",
    "Progress",
    "Last Updated",
  ];
  const addField = (field) => {
    setVisibleFields((prevVisibleFields) => {
      if (!prevVisibleFields?.includes(field)) {
        return [...prevVisibleFields, field];
      }
      return prevVisibleFields;
    });
  };

  const removeField = (field, name) => {
    setformData((prevFormData) => {
      let updatedFormData = { ...prevFormData };

      if (name === "goal_owner" || name === "okr_owner") {
        updatedFormData[name] = [];
      } else if (name === "progress") {
        updatedFormData[name] = {
          comparison: "equal_to",
          value: null,
        };
      } else if (name === "lastUpdated") {
        updatedFormData[name] = {
          comparison: "before",
          value1: null,
          value2: null,
        };
      } else {
        updatedFormData[name] = "";
      }

      return updatedFormData;
    });

    setVisibleFields((prevVisibleFields) => {
      return prevVisibleFields?.filter((f) => f !== field);
    });
  };

  const [selectedGoalIndex, setSelectedGoalIndex] = useState(-1);

  const handleCheckboxChange = (index, goal) => {
    if (selectedGoalIndex === index) {
      setSelectedGoalIndex(-1);
      setSelectedGoal(null);
      setChatbotOpen(false);
    } else if (selectedGoalIndex >= 0 && selectedGoalIndex !== index) {
      return;
    } else {
      setSelectedGoalIndex(index);
      setSelectedGoal(goal);
    }
  };

  const handleChatbotButton = () => {
    if (!plan?.free_trial_status && !plan?.features?.AI_Chatbot) {
      dispatch(handleOpenPlanModal());
    } else {
      setChatbotOpen(!isChatbotOpen);
    }
  };

  useEffect(() => {
    setSelectedGoalIndex(-1);
    setChatbotOpen(false);
  }, [goals]);

  return (
    <div className="w-full h-screen p-4 font-poppins cursor-default">
      <div className="flex justify-between mb-5">
        <span className="flex flex-col gap-y-2">
          <span
            style={{ color: "#063F5D", opacity: "90%" }}
            className="font-bold text-4xl"
          >
            Goals
            <sup
              style={{
                marginLeft: "5px",
                fontSize: "18px",
                verticalAlign: "super",
              }}
            >
              <div className="relative inline-flex">
                <div className="w-2 h-2 bg-basic rounded-full"></div>
                <div className="w-2 h-2 bg-basic rounded-full absolute top-0 left-0 animate-ping"></div>
                <div className="w-2 h-2 bg-basic rounded-full absolute top-0 left-0 animate-pulse"></div>
              </div>
            </sup>
          </span>
          <span>
            <div
              className=" font-medium text-sm italic cursor-pointer whitespace-nowrap "
              style={{
                color: "rgba(6, 63, 93, 0.8)",
              }}
            >
              <Link to={"/home/sessions"}>
                Sessions / {getNameBySessionId(okrSlice?.session)}{" "}
              </Link>{" "}
              {/* <Link to={"/home/sessions/" + goalData?.goal_id}>
                         {goalData?.title || goalData?.goal_name}
                          </Link> */}
            </div>
          </span>
        </span>
        <span className="flex justify-between gap-x-4">
          <span className="flex justify-center items-center relative">
            <Popover
              isLazy
              placement="bottom-end"
              // lazyBehavior="keepMounted"
              onClose={() => setPopoverOpen(false)}
            >
              <PopoverTrigger>
                <Button
                  px={4}
                  py={2}
                  width={"100px"}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  _focus={{ boxShadow: "outline" }}
                  backgroundColor="white"
                  boxShadow={popoverOpen ? "outline" : ""}
                  color="#3C93C2"
                  fontWeight={"normal"}
                  borderColor="#3C93C2"
                  _hover={{
                    bg: "#F7FAFC",
                    color: "#287199",
                    borderColor: "#287199",
                    boxShadow: "outline",
                  }}
                  onClick={() => setPopoverOpen(true)}
                  className="relative"
                >
                  <span className="flex justify-evenly items-center gap-x-2">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00017 13.7C6.80017 13.7 6.6335 13.6334 6.50017 13.5C6.36683 13.3667 6.30017 13.2 6.30017 13V7.55005L1.02517 0.900049C0.925167 0.766715 0.912667 0.633382 0.987667 0.500049C1.06267 0.366715 1.17517 0.300049 1.32517 0.300049H12.6752C12.8252 0.300049 12.9377 0.366715 13.0127 0.500049C13.0877 0.633382 13.0752 0.766715 12.9752 0.900049L7.70017 7.55005V13C7.70017 13.2 7.6335 13.3667 7.50017 13.5C7.36683 13.6334 7.20017 13.7 7.00017 13.7Z"
                        fill="#0A4464"
                      />
                    </svg>
                    <p
                      style={{
                        color: "#3C93C2",
                      }}
                    >
                      Filters
                    </p>{" "}
                    <ChevronDownIcon />
                  </span>
                </Button>

                {/* <Button>Trigger</Button> */}
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <div>
                    <FilterComponent
                      filterModal={filterModal}
                      setformData={setformData}
                      formData={formData}
                      owners={owners}
                      values={values}
                      removeField={removeField}
                      addField={addField}
                      visibleFields={visibleFields}
                      setVisibleFields={setVisibleFields}
                    />
                  </div>
                </PopoverContent>
              </Portal>
            </Popover>
          </span>
          <span
            className="flex justify-end items-center font-medium"
            style={{ color: "#419EBD" }}
          >
            {formatDate()}
          </span>
        </span>
      </div>
      <div
        className="flex flex-col p-4 cursor-default"
        style={{
          backgroundColor: "#F0F5FC",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <h1
          className="font-medium text-black mb-3 flex"
          style={{ color: "#0F7BB3", opacity: "80%", fontSize: "28px" }}
        >
          Amazing Progress,{" "}
          <span className="capitalize ml-2">
            {currentUser?.username?.split(" ")[0]}!
          </span>
          <span className="flex items-center justify-start">
            <svg
              width="118"
              height="25"
              viewBox="0 0 118 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                strokeWidth: "1.32067",
                verticalAlign: "middle",
              }}
            >
              <path
                d="M1 18.587C5.7027 16.2018 11.4706 17.5503 16.6757 19.5737C43.9868 30.191 32.5107 7.20981 48.027 7.20981C59 7.20981 59.7838 33.0617 74.6757 11.3311C89.5676 -10.3994 80.1622 23.7467 95.0461 23.7467C109.237 23.7467 104.675 7.20981 117 1.58984"
                stroke="url(#paint0_linear_385_2121)"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_385_2121"
                  x1="16.225"
                  y1="18.946"
                  x2="117.186"
                  y2="-14.0898"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FEF7F1" stopOpacity="0" />
                  <stop offset="0.749947" stopColor="#FB4646" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </h1>
        {selectedGoalIndex >= 0 && (
          <div className="w-full bg-basic rounded-md text-white p-2 mb-2">
            <div className="flex justify-between">
              <p className="flex items-center text-lg">1 selected</p>
              <button
                className="bg-white text-black px-4 py-2"
                onClick={() => {
                  handleChatbotButton();
                  setSummarize(true);
                }}
              >
                Summarize
              </button>
            </div>
          </div>
        )}
        <div className="p-4 h-full bg-white rounded-lg overflow-x-hidden">
          <CustomScrollbar>
            <span className="flex h-fit mx-4">
              <span className="flex items-center">
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.80002 14.5667C4.4397 14.5667 3.28296 14.0901 2.32979 13.1369C1.37661 12.1838 0.900024 11.027 0.900024 9.6667C0.900024 8.30638 1.37661 7.14963 2.32979 6.19647C3.28296 5.24329 4.4397 4.7667 5.80002 4.7667C7.16035 4.7667 8.31709 5.24329 9.27026 6.19647C10.2234 7.14963 10.7 8.30638 10.7 9.6667C10.7 11.027 10.2234 12.1838 9.27026 13.1369C8.31709 14.0901 7.16035 14.5667 5.80002 14.5667ZM5.80779 12.9334C6.71372 12.9334 7.48336 12.6141 8.11669 11.9756C8.75002 11.3371 9.06669 10.5649 9.06669 9.65893C9.06669 8.753 8.74744 7.98337 8.10892 7.35003C7.4704 6.7167 6.69818 6.40003 5.79226 6.40003C4.88632 6.40003 4.11669 6.71929 3.48336 7.3578C2.85002 7.99632 2.53336 8.76854 2.53336 9.67447C2.53336 10.5804 2.85261 11.35 3.49112 11.9834C4.12965 12.6167 4.90187 12.9334 5.80779 12.9334ZM11.7834 9.88337C11.7722 9.8167 11.7667 9.77402 11.7667 9.75533V9.6667C11.7667 8.01343 11.1856 6.60567 10.0233 5.44342C8.86105 4.28116 7.45329 3.70003 5.80002 3.70003H5.71139C5.6927 3.70003 5.65002 3.69448 5.58336 3.68337C5.91669 2.67225 6.50836 1.8667 7.35836 1.2667C8.20836 0.666699 9.15747 0.366699 10.2057 0.366699C11.5575 0.366699 12.7111 0.844482 13.6667 1.80005C14.6222 2.7556 15.1 3.90927 15.1 5.26103C15.1 6.30925 14.8 7.26114 14.2 8.1167C13.6 8.97225 12.7945 9.56114 11.7834 9.88337Z"
                    fill="#CC0A0A"
                  />
                </svg>
              </span>
              <span
                style={{
                  fontSize: "24px",
                  color: "#063F5D",
                  fontWeight: "600",
                  marginLeft: "3px",
                }}
              >
                Goals List
              </span>
            </span>
            {!goals || goalsLoading ? (
              <div className="h-full flex justify-center items-center mx-auto">
                <img src="/assets/images/Group 3.svg" />
              </div>
            ) : goals?.length ? (
              <div>
                <div className="grid grid-cols-3 w-full mt-5">
                  <div className="flex items-center col-span-1 justify-center">
                    <h3
                      className="text-lg font-medium"
                      style={{ color: "#0098CA" }}
                    >
                      Goals
                    </h3>
                  </div>
                  <div className="flex items-center col-span-1 justify-center">
                    <h3
                      className="text-lg font-medium"
                      style={{ color: "#0098CA" }}
                    >
                      Progress
                    </h3>
                  </div>
                  <div className="flex items-center col-span-1 justify-center">
                    <h3
                      className="text-lg font-medium"
                      style={{ color: "#0098CA" }}
                    >
                      Description
                    </h3>
                  </div>
                </div>
                {goals?.slice()?.map((goal, i) => (
                  <div
                    className={`grid grid-cols-3 w-full py-4 pl-8`}
                    style={{
                      boxShadow: "2px 4px 8px 1px #E7EEF9",
                    }}
                    key={i}
                  >
                    <div className="flex items-center gap-x-2 col-span-1 w-full px-2">
                      <Checkbox
                        checked={selectedGoalIndex === i}
                        disabled={
                          selectedGoalIndex !== i && selectedGoalIndex >= 0
                        }
                        onChange={() => handleCheckboxChange(i, goal)}
                      />
                      <div
                        onClick={() =>
                          navigate("/home/sessions/" + goal?.goal_id)
                        }
                        className="text-sm text-blue-950 hover:text-gray-500 font-semibold  transition-all items-center flex px-10 gap-2 cursor-pointer"
                      >
                        <button
                          className="rounded p-1"
                          style={{
                            background:
                              "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                          }}
                        >
                          <GoGoal
                            className="text-md"
                            style={{
                              color: "#B91C1C",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        </button>
                        <p className="text-base text-basic items-center font-medium">
                          {goal?.title || goal?.goal_name}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
                      <div className="flex justify-between w-1/2">
                        <div className="text-sm" style={{ color: "#0C0C0C" }}>
                          {goal?.overall_gain === 0
                            ? "Pending"
                            : goal?.overall_gain < 100
                            ? "In Progress"
                            : "Completed"}
                        </div>

                        <div>
                          <h3
                            className="text-base font-bold"
                            style={{ color: "#063F5D" }}
                          >
                            {goal?.overall_gain}
                            <span className="font-medium">%</span>
                          </h3>
                        </div>
                      </div>
                      <div className="flex justify-center w-1/2 bg-inherit items-center">
                        <Progress
                          percent={goal?.overall_gain}
                          showInfo={false}
                          strokeColor={
                            goal?.overall_gain < 100 ? "#F77C33" : "#0AA959"
                          }
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-center gap-x-2 col-span-1">
                      <span className="text-sm text-basic opacity-70">
                        {goal?.description}
                      </span>
                    </div>
                    <div className="col-span-12">
                      {goal?.children?.map((okr, index) => (
                        <div
                          className={`grid grid-cols-3 w-full py-4  `}
                          key={index}
                        >
                          <div
                            className="text-xs text-blue-950 ml-5 items-center hover:text-gray-500 font-medium transition-all flex  gap-2 cursor-pointer col-span-1 pl-20"
                            onClick={() => {
                              navigate(`/home/sessions/${goal?.goal_id}`);
                              dispatch(handleOpenOkrDrawer(okr?.okr_id));
                            }}
                          >
                            <button className="">
                              <img
                                src="/assets/images/objective.svg"
                                style={{
                                  width: "27px",
                                  height: "27px",
                                }}
                              />
                            </button>
                            <p className="text-basic font-medium text-base">
                              {okr?.title}
                            </p>
                          </div>
                          <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
                            <div className="flex justify-between w-1/2">
                              <div
                                className="text-sm"
                                style={{ color: "#0C0C0C" }}
                              >
                                {okr?.overall_gain === 0
                                  ? "Pending"
                                  : okr?.overall_gain < 100
                                  ? "In Progress"
                                  : "Completed"}
                              </div>

                              <div>
                                <h3
                                  className="text-base font-bold"
                                  style={{ color: "#063F5D" }}
                                >
                                  {okr?.overall_gain}
                                  <span className="font-medium">%</span>
                                </h3>
                              </div>
                            </div>
                            <div className="flex justify-center w-1/2 bg-inherit items-center">
                              <Progress
                                percent={okr?.overall_gain}
                                showInfo={false}
                                strokeColor={
                                  okr?.overall_gain < 100
                                    ? "#F77C33"
                                    : "#0AA959"
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-center ml-5 justify-center gap-x-2 col-span-1">
                            <span className="text-sm text-basic opacity-70">
                              {okr?.description}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="z-auto flex flex-col absolute bottom-5  right-5 p-2">
                  {isChatbotOpen && (
                    <div
                      style={{
                        transition:
                          "width 1s cubic-bezier(0.42, 0, 0.58, 1), height 1s cubic-bezier(0.42, 0, 0.58, 1)",
                      }}
                    >
                      {" "}
                      <ChatBotComponent
                        data={selectedgoal}
                        summarize={summarize}
                        setSummarize={setSummarize}
                        handleClose={handleChatbotButton}
                      />
                    </div>
                  )}
                  {!isChatbotOpen && (
                    <div
                      className=" mb-2 mr-5 text-sm shadow-sm right-10 rounded-t-md rounded-bl-md p-3"
                      style={{ backgroundColor: "#FFD580" }}
                    >
                      Hi 👋! I'm Joyce, your chatbot assistant. How may I help
                      you?{" "}
                    </div>
                  )}
                  <div className="flex justify-end">
                    <Button
                      p={2}
                      borderRadius="full"
                      backgroundColor="#063F5D"
                      color="white"
                      _hover={{
                        padding: "4px",
                        bg: "#042a45",
                      }}
                      onClick={handleChatbotButton}
                      className="relative flex justify-end"
                    >
                      <span className="flex justify-end items-center gap-x-2">
                        {isChatbotOpen ? (
                          <KeyboardArrowDownIcon className=" text-white" />
                        ) : (
                          <MdChatBubbleOutline className="text-white" />
                        )}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="box-border m-0">
                <div className="flex flex-col items-center content-center justify-center min-h-screen">
                  <h1 className="text-2xl font-medium text-center text-slate-500">
                    No Goals here. It's time to define <br />
                    your first Goal!
                  </h1>
                  <button
                    className="px-5 py-1.5 my-4 text-white bg-button hover:shadow-lg rounded-full "
                    onClick={() => {
                      if (
                        plan?.plan_name === "Free" &&
                        !plan?.free_trial_status
                      ) {
                        dispatch(handleOpenPlanModal());
                      } else {
                        dispatch(handleOpenCreateGoalModal({ isOpen: true }));
                      }
                    }}
                  >
                    Create Goals
                  </button>
                </div>
              </div>
            )}
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default TableView;
