import React from "react";
import CustomProgressBar from "./CustomProgressBar";
import { Progress } from "antd";

const StrategicReportBox = ({ teamsData }) => {
  return (
    <div className="w-full grid grid-cols-4 gap-4 mt-2">
      {teamsData &&
        teamsData?.map((team,index) => (
          <div key={index}>
            <div
              key={team?.team_id}
              className="px-4 py-2 rounded-lg"
              style={{
                boxShadow:
                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
              }}
            >
              <h5 className="text-lg font-medium mb-1 text-basic ">
                {team?.team_name}
              </h5>
              <div className="text-xs">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-basic">Goal Progress</h5>
                    <h5 className="text-sm">{team?.goal_progress} %</h5>
                  </div>
                  <div className="flex justify-center gap-3 flex-col w-full">
                    <Progress
                      percent={team?.goal_progress}
                      showInfo={false}
                      strokeColor={
                        team?.goal_progress < 100 ? "#F77C33" : "#0AA959"
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-basic">OKR Progress</h5>
                    <h5 className="text-sm">{team?.okr_progress} %</h5>
                  </div>
                  <div className="flex justify-center gap-3 flex-col w-full">
                    <Progress
                      percent={team?.okr_progress}
                      showInfo={false}
                      strokeColor={
                        team?.okr_progress < 100 ? "#F77C33" : "#0AA959"
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-basic">Key Results Progress</h5>
                    <h5 className="text-sm">{team?.key_progress} %</h5>
                  </div>
                  <div className="flex justify-center gap-3 flex-col w-full">
                    <Progress
                      percent={team?.key_progress}
                      showInfo={false}
                      strokeColor={
                        team?.key_progress < 100 ? "#F77C33" : "#0AA959"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default StrategicReportBox;
