// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.forget_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.wrapper_container {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    /* padding: 20px; */
}

label {
    font-size: 14px;
}

p {
    font-size: 14px;
}

input {
    width: 350px;
    height: 35px;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    font-size: 15px;
    margin-top: 5px;
    border: 1px solid #1e90ff;
}

.forget_button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 15px;
    border-radius: 5px;
    outline: none;
    font-size: 15px;
    background-color: #FC9334;
    border: none;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Forgetpassword.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,4CAA4C;IAC5C,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,eAAe;IACf,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.forget_container{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n}\n\n.wrapper_container {\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    border-radius: 10px;\n    /* padding: 20px; */\n}\n\nlabel {\n    font-size: 14px;\n}\n\np {\n    font-size: 14px;\n}\n\ninput {\n    width: 350px;\n    height: 35px;\n    border-radius: 3px;\n    padding: 10px;\n    outline: none;\n    font-size: 15px;\n    margin-top: 5px;\n    border: 1px solid #1e90ff;\n}\n\n.forget_button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    padding: 8px 15px;\n    border-radius: 5px;\n    outline: none;\n    font-size: 15px;\n    background-color: #FC9334;\n    border: none;\n    width: 100%;\n    color: #ffffff;\n    cursor: pointer;\n    margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
