import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import DashboardLayout from "./pages/Users/DashboardLayout";
import { ViewOkr } from "./pages/Goals/Okr/ViewOkr";
import { googleClientId } from "./utils/config";
import { Employees } from "./pages/People/Employees";
import { ChakraProvider } from "@chakra-ui/react";
import Home from "./pages/Home";
import AllGoals from "./pages/Goals/AllGoals";
import { Teams } from "./pages/People/Teams";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ReportDrafts from "./pages/Report/ReportDrafts";
import ReportDraftPage from "./pages/Report/ReportDraftPage";
import ExecutiveInsights from "./pages/Insights/ExecutiveInsights";
import StrategicReport from "./pages/Insights/StrategicReport";
import BilingPage from "./pages/Billing/BilingPage";
import EmailVerification from "./pages/EmailVerification";
import DashboardMain from "./pages/DashboardMain";
import React, { Suspense } from "react";

const Features = React.lazy(() => import("./components/Features"));
const ContactUs = React.lazy(() => import("./components/ContactUs"));
const Integration = React.lazy(() =>
  import("./pages/Integrations/Integration")
);
const JIRAComponent = React.lazy(() =>
  import("./components/Integrations/JIRA")
);

function App() {
  return (
    <ChakraProvider>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/resources"
              element={
                <Suspense fallback={<p>loading...</p>}>
                  <Features />
                </Suspense>
              }
            />
            <Route
              path="/contactus"
              element={
                <Suspense fallback={<p>loading...</p>}>
                  <ContactUs />
                </Suspense>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route element={<DashboardLayout />}>
              <Route path="/home/dashboard" element={<DashboardMain />} />
              <Route
                path="/home/integrations"
                element={
                  <Suspense fallback={<p>loading...</p>}>
                    <Integration />
                  </Suspense>
                }
              />
              <Route
                path="/home/integrations/jira"
                element={
                  <Suspense fallback={<p>loading...</p>}>
                    <JIRAComponent />
                  </Suspense>
                }
              />
              <Route path="/home/sessions" element={<AllGoals />} />
              <Route path="/home/sessions/:goalid" element={<ViewOkr />} />
              <Route path="/home/people" element={<Employees />} />
              <Route path="/home/people/teams" element={<Teams />} />
              <Route path="/home/reports" element={<ReportDrafts />} />
              <Route path="/home/reports/:id" element={<ReportDraftPage />} />
              <Route path="/home/insight" element={<ExecutiveInsights />} />
              <Route
                path="/home/insight/strategic"
                element={<StrategicReport />}
              />
              <Route path="/home/billing" element={<BilingPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
}

export default App;
