import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  ListItem,
  Button,
  Flex,
  UnorderedList,
  Card,
  CardBody,
  CardFooter,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Pricing = ({ showTitle, handleClick,widthNew }) => {
  const [isActiveMonthlyPlan, setIsActiveMonthlyPlan] = useState(true);
  const [isActiveYearlyPlan, setIsActiveYearlyPlan] = useState(false);
  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate("/contactus");
  };


  const divStyle = {
    width: widthNew ? "330px" : "450px",
    padding: "0px"
  };

  const pricingDatamonthly = [
    {
      title: "Basic",
      heading: "Transparent pricing",
      name: "No hidden fees ever",
      price: "$5",
      features: [
        "Create Goals & OKRs",
        "Clone Goals and OKRs",
        "Track OKR Progress",
        "Alignment view",
        "Cascading views",
        "Timely Reminders",
        "Notifications",
        "Tag team members",
        "Global Search & Filters",
        "Team and user profiles",
        "Reporting & Analytics",
        "Integrations (example: Jira)",
        "Help center and knowledge base",
        showTitle ? "Up to 5 Team members" : "Up to 3 Team members",
      ],
    },
    {
      title: "Expanded",
      heading: "Flexible contracts",
      name: "Only pay for what you use",
      price: "$10",
      features: [
        "Includes Basic +",
        "Custom data integrations",
        "Executive insights",
        "AI Chatbot assisted goal creation",
        "AI Chatbot assisted executive results summary",
        "AI Chatbot assisted goal creation with template (.docx/ PDF)",
        "Standard support",
        "up to 50 team members",
      ],
    },
    {
      title: "Premium",
      heading: "No vendor lock-in",
      name: "Your data is yours, always",
      // price: "$15.00",
      features: [
        "Includes Expanded +",
        "Dedicated account manager",
        "Coaching Services",
        "Quarterly QBRs",
        "Admin Panel",
        "SSO (SAML)",
        "PPT/PDF Reports export",
        "100 + Team member",
      ],
      demoButton: true,
    },
  ];

  const pricingDataAnually = [
    {
      title: "Basic",
      heading: "Transparent pricing",
      name: "No hidden fees ever",
      price: "$48",
      features: [
        "Create Goals & OKRs",
        "Clone Goals and OKRs",
        "Track OKR Progress",
        "Alignment view",
        "Cascading views",
        "Timely Reminders",
        "Notifications",
        "Tag team members",
        "Global Search & Filters",
        "Team and user profiles",
        "Reporting & Analytics",
        "Integrations (example: Jira)",
        "Help center and knowledge base",
        showTitle ? "Up to 5 Team members" : "Up to 3 Team members",
      ],
    },
    {
      title: "Expanded",
      heading: "Flexible contracts",
      name: "Only pay for what you use",
      price: "$96",
      features: [
        "Includes Basic +",
        "Custom data integrations",
        "Executive insights",
        "AI Chatbot assisted goal creation",
        "AI Chatbot assisted executive results summary",
        "AI Chatbot assisted goal creation with template (.docx/ PDF)",
        "Standard support",
        "up to 50 team members",
      ],
    },
    {
      title: "Premium",
      heading: "No vendor lock-in",
      name: "Your data is yours, always",
      // price: "$120",
      features: [
        "Includes Expanded +",
        "Dedicated account manager",
        "Coaching Services",
        "Quarterly QBRs",
        "Admin Panel",
        "SSO (SAML)",
        "PPT/PDF Reports export",
        "100 + Team member",
      ],
      demoButton: true,
    },
  ];

  return (
    <Box id="pricing" className="md:px-6 py-10 overflow-x-hidden">
      {showTitle && (
        <Heading
          as="h2"
          fontSize={{ base: "2xl", md: "4xl" }}
          color={"#14285a"}
          textAlign={"center"}
        >
          iMongu Plans
        </Heading>
      )}
      <Box display={"flex"} justifyContent={"center"} mb={"5"} mt={3}>
        <Flex
          borderRadius={"full"}
          bg={"#ECECEC"}
          p={"8px 10px"}
          justifyContent={"center"}
          gap={2}
        >
          <Box
            borderRadius={"full"}
            bg={isActiveMonthlyPlan ? "#F27E0D" : "#ECECEC"}
            p={"8px 25px"}
            cursor={"pointer"}
            black={"#212121"}
            onClick={() => {
              setIsActiveMonthlyPlan(true);
              setIsActiveYearlyPlan(false);
            }}
          >
            <Text color={isActiveMonthlyPlan ? "#FFFFFF" : "#000000"}>
              Monthly
            </Text>
          </Box>
          <Box
            borderRadius={"full"}
            bg={isActiveYearlyPlan ? "#F27E0D" : "#ECECEC"}
            p={"8px 25px"}
            cursor={"pointer"}
            onClick={() => {
              setIsActiveYearlyPlan(true);
              setIsActiveMonthlyPlan(false);
            }}
          >
            <Text color={isActiveYearlyPlan ? "#FFFFFF" : "#000000"}>
              Yearly
            </Text>
          </Box>
        </Flex>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {isActiveMonthlyPlan &&
          pricingDatamonthly.map((plan, index) => (
            <PricingCard
              key={index}
              title={plan.title}
              divStyle={divStyle}
              heading={plan.heading}
              name={plan.name}
              price={plan.price}
              features={plan.features}
              // describe={plan.describe}
              demoButton={plan.demoButton}
              handleDemoClick={handleDemoClick}
              planType="monthly"
              showTitle={showTitle}
              handleClick={handleClick}
            />
          ))}

        {isActiveYearlyPlan &&
          pricingDataAnually.map((plan, index) => (
            <PricingCard
              key={index}
              title={plan.title}
              divStyle={divStyle}
              heading={plan.heading}
              name={plan.name}
              price={plan.price}
              features={plan.features}
              // describe={plan.describe}
              demoButton={plan.demoButton}
              handleDemoClick={handleDemoClick}
              planType="yearly"
              showTitle={showTitle}
              handleClick={handleClick}
            />
          ))}
      </SimpleGrid>
    </Box>
  );
};

const PricingCard = ({
  title,
  heading,
  name,
  price,
  features,
  planType,
  demoButton,
  handleDemoClick,
  showTitle,
  handleClick,
  divStyle,
}) => (
  <div className="md:px-6  py-10 " style={divStyle}>
    <Card className="shadow-2xl md:w-11/12 md:h-full p-2 transition-transform transform-gpu hover:scale-105 hover:shadow-md ">
      <CardBody>
        <Heading as="h3" fontSize="3xl" mb={4} textAlign={"center"}>
          {title}
        </Heading>

        <Text as="h4" fontSize="xl" textAlign={"center"}>
          {heading}
        </Text>
        <Text
          as="h4"
          fontSize="lg"
          mb={2}
          textAlign={"center"}
          color={"#F7AD18"}
        >
          {name}
        </Text>
        {!demoButton && (
          <Text
            fontSize="4xl"
            fontWeight="bold"
            mb={4}
            textAlign={"center"}
            color={"#F7AD18"}
          >
            {price}
            <Flex direction="column" align="center" justify="center">
              <Text as="span" color="gray.500" fontSize="md">
                user/{planType === "monthly" ? "month" : "year"}
              </Text>
            </Flex>
          </Text>
        )}

        <UnorderedList>
          {features.map((feature, index) => (
            <ListItem key={index} fontSize="md" mt={2} color="gray.500">
              {feature}
            </ListItem>
          ))}
        </UnorderedList>
      </CardBody>
      <CardFooter
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {!demoButton ? (
          <>
            {showTitle ? (
              <Link className="w-3/5" to="/Register">
                <Button
                  colorScheme="#F27E0D"
                  w="full"
                  px={8}
                  borderRadius="20px"
                  bgColor="#F27E0D"
                  className="hover:bg-button-hover transition-all"
                >
                  Subscribe
                </Button>
              </Link>
            ) : (
              <Box className="w-3/5">
                <Button
                  colorScheme="#F27E0D"
                  w="full"
                  px={8}
                  borderRadius="20px"
                  bgColor="#F27E0D"
                  className="hover:bg-button-hover transition-all"
                  onClick={handleClick}
                >
                  Subscribe
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box className="w-3/5">
            <Button
              colorScheme="#F27E0D"
              w="full"
              px={8}
              borderRadius="20px"
              bgColor="#F27E0D"
              className="hover:bg-button-hover transition-all"
              onClick={handleDemoClick}
            >
              Book a demo <ArrowForwardIcon className="ml-1" />
            </Button>
          </Box>
        )}
      </CardFooter>
    </Card>
  </div>
);

export default Pricing;
