import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenPlanModal,
  handleUpdateCurrentCompany,
  handleUpdatePlan,
  logout,
} from "../../stores/slices/userAuthSlice";
import { LogoutOutlined } from "@ant-design/icons";
import { Dropdown, Modal } from "antd";
import SwitchAccount from "./SwitchAccount";
import ProfileModal from "./ProfileModal";
import NotificationModal from "../Notification";
import { NotifyURL, baseURL } from "../../utils/config";
import axios from "axios";
import CreateGoalModal from "../Okrs/Goals/CreateGoalModal";
import {
  handleCloseCreateGoalModal,
  handleCreateTeamDrawer,
  handleOpenCreateGoalModal,
  handleOpenCreateOkrModal,
  handleOpenCreateReport,
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
  handleOpenTeamDrawer,
} from "../../stores/slices/okrSlice";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { handleUpdateDraftSlice } from "../../stores/slices/draftSlice";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CustomScrollbar from "./CustomScrollBar";
import { ToastContainer, toast } from "react-toastify";

const SearchAndButton = () => {
  const connection = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userold = useSelector((state) => state.user);
  const currentUser = userold?.currentUser;
  const [isActiveMenuOpen, setIsActiveMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const currentEmployee = currentUser?.employees[userold?.currentCompany];
  const [profileModal, setProfileModal] = useState(false);
  const [data, setData] = useState([]);
  const okrSlice = useSelector((state) => state.okr);
  const [count, setCount] = useState(0);
  const divRef = useRef(null);
  const plan = userold?.currentPlan;

  useEffect(() => {}, [currentUser]);

  const items = [
    {
      key: "1",
      onClick: () => {
        setProfileModal(true);
      },
      label: (
        <div
          target="_blank"
          rel="noopener noreferrer"
          className="w-24"
          href="https://www.antgroup.com"
        >
          Manage Profile
        </div>
      ),
    },

    {
      key: "4",
      label:
        currentUser?.employees?.length > 1 ? (
          <div
            onClick={() => {
              setOpenModal(true);
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.luohanacademy.com"
          >
            Switch Companies
            <hr />
          </div>
        ) : null,
    },

    {
      key: "3",
      label: (
        <div
          onClick={() => {
            dispatch(logout());
          }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Logout
        </div>
      ),
      icon: <LogoutOutlined />,
    },
  ];
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleCompanyChange = (index) => {
    dispatch(handleUpdateCurrentCompany(index));
    setOpenModal(false);
    window.location.href = "/home/dashboard";
  };

  const handleMenuOpen = () => {
    setIsActiveMenuOpen(!isActiveMenuOpen);
  };

  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleNotificationOpen = () => {
    if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
      dispatch(handleOpenPlanModal());
    } else {
      setNotificationOpen(!notificationOpen);
    }
  };

  const handleCount = (countNew) => {
    setCount(countNew);
  };

  const Notificationcall = async () => {
    try {
      const response = await axios.get(baseURL + "/notification/", {
        params: {
          company_id: currentEmployee?.company_id,
          user_id: currentEmployee?.user_id,
        },
      });
      setData(response?.data?.notifications);
      handleCount(response?.data?.unseen_count);
    } catch (error) {}
  };

  useEffect(() => {
    Notificationcall();
  }, [currentUser]);

  const deleteAllnotification = async () => {
    try {
      const res = await axios.delete(baseURL + `/notification/`, {
        params: {
          company_id: currentEmployee?.company_id,
          user_id: currentEmployee?.user_id,
        },
      });
      Notificationcall();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationClick = async ({ index, item }) => {
    if (!item?.is_seen) {
      setupWebSocket.current = false;
      try {
        const result = await axios.put(baseURL + "/notification/", {
          isSeen: "true",
          notify_id: item?.notify_id,
          user_id: currentEmployee?.user_id,
        });
        Notificationcall();
        setupWebSocket();
      } catch (err) {
        console.log(err);
        Notificationcall();
        setupWebSocket();
      }
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      const res = await axios.delete(baseURL + `/notification/`, {
        params: {
          id: id,
        },
      });
      Notificationcall();
    } catch (error) {
      console.log(error);
    }
  };

  const setupWebSocket = () => {
    const socket = new WebSocket(NotifyURL + `${currentEmployee?.company_id}/`);

    // Connection opened
    socket.addEventListener("open", (event) => {
      socket.send("Connection established");
    });

    // Listen for messages
    const handleMessage = (event) => {
      const newData = JSON.parse(event.data);

      if (newData?.notify_id) {
        setData((prevData) => {
          const isNewDataUnique = !prevData.some(
            (item) => item.notify_id === newData.notify_id
          );

          if (isNewDataUnique) {
            setCount((prevCount) => prevCount + 1); // Update count only when newData is added
            return [newData, ...prevData];
          } else {
            return prevData;
          }
        });
      }
    };

    socket.addEventListener("message", handleMessage);

    connection.current = socket;
  };

  useEffect(() => {
    if (!setupWebSocket.current) {
      setupWebSocket();
      setupWebSocket.current = true;
    }
  }, []);
  const [titleError, setTitleError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [sessionError, setSessionError] = useState(false); // State variable to track the selected option
  const [results, setResults] = useState([]);
  const [formDatanew, setformDatanew] = useState({
    session: okrSlice?.session || null,
    description: "",
    title: "",
    owner: [],
  });
  useEffect(() => {
    setformDatanew({
      session: null,
      title: "",
      owner: [],
      description: "",
    });
  }, [okrSlice?.openCreateGoalModal]);

  const [createGoalLoading, setCreateGoalLoading] = useState(false);
  const getGoals = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
      }
      const result = await axios.get(baseURL + "/goal/", {
        params: {
          company_id: company_id,
          session: okrSlice?.session,
        },
      });
    } catch (error) {
      if (error?.response?.status === 404) {
      }
      console.log(error);
    }
  };

  const showModal = () => {
    dispatch(handleOpenCreateGoalModal({ isOpen: true }));
  };

  const matchGoalRoute = useMatch("/home/sessions/:goalid");
  const matchPeopleRoute = location.pathname === "/home/people";

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformDatanew((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const getCurrentPlanData = async () => {
    try {
      const currentCompany =
        userold?.currentUser?.employees[userold?.currentCompany];
      const result = await axios.get(baseURL + "/subscription/", {
        params: {
          user_id: currentCompany?.user_id,
          company_id: currentCompany?.company_id,
        },
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const saveData = async (event) => {
    event.preventDefault();
    let user_id = [];
    let team_id = [];
    let parent;
    let parent_type;
    if (formDatanew?.session === null || formDatanew.session === undefined || formDatanew?.session === '') {
      setSessionError(true);
      return;
    }
    if (!formDatanew.title) {
      setTitleError(true);
      return;
    }
    if (!formDatanew?.owner?.length) {
      setOwnerError(true);

      return;
    } else {
      formDatanew?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    }
    if (formDatanew?.parent_val) {
      const parentArr = formDatanew?.parent_val.split(" ");
      parent = parentArr[0];
      parent_type = parentArr[1];
    }

    try {
      setCreateGoalLoading(true);

      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
        login_user_id = currentEmployee[userold?.currentCompany]?.user_id;
      }

      const result = await axios.post(baseURL + "/goal/", {
        title: formDatanew.title,
        user_id: user_id,
        team_id: team_id,
        description: formDatanew.description,
        company_id: company_id,
        session: formDatanew.session,
        parent: parent,
        parent_type: parent_type,
        login_user_id: login_user_id,
      });
      setformDatanew({
        session: okrSlice?.session || null,
        title: "",
        owner: [],
        description: "",
      });
      getCurrentPlanData();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "goal_created",
        message: "Goal created succesfully!.",
        id: result?.data?.goal_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      navigate("/home/sessions");
    } catch (err) {
      console.log(err);

      setformDatanew({
        session: okrSlice?.session || null,
        title: "",
        owner: [],
        description: "",
      });
    } finally {
      getGoals();
      dispatch(handleCloseCreateGoalModal({ isOpen: false }));
      setCreateGoalLoading(false);
    }
  };

  const [openCreateReport, setOpenCreateReport] = useState(false);
  const [draftReports, setDraftReports] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [selectedSearchOption, setSearchSelectedOption] =
    useState("everywhere");
  const handleOptionSelect = (item) => {
    setSearchSelectedOption(item);
  };

  const handleSearchChange = async (event) => {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      return;
    }
    setShowResults(true);
    let doc = [];
    if (
      selectedSearchOption === "goals" ||
      selectedSearchOption === "okrs" ||
      selectedSearchOption === "key_results"
    ) {
      doc.push("title");
    } else if (selectedSearchOption === "employees") {
      doc.push("username");
    } else if (selectedSearchOption === "teams") {
      doc.push("team_name");
    } else if (selectedSearchOption === "reports") {
      doc.push("name");
    } else if (selectedSearchOption === "everywhere") {
      doc.push("title", "username", "team_name", "name");
    }

    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[userold?.currentCompany]?.company_id;
      }

      const result = await axios.post(
        baseURL + `/search/${event.target.value.toLowerCase()}/`,
        {
          company_id: company_id,
          searchField: doc,
          document_type: selectedSearchOption,
        }
      );
      if (result && result.data.length > 0) {
        setResults(result.data);
        setShowResults(true);
      } else {
        setResults(result.data);
        setShowResults(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const fetchData = async () => {
    try {
      const currentCompany = currentUser?.employees[userold?.currentCompany];
      const res = await axios.get(baseURL + "/stats/", {
        params: {
          company_id: currentCompany?.company_id,
          user_id: currentCompany?.user_id,
        },
      });
      setDraftReports(res.data || []);
      dispatch(handleUpdateDraftSlice(res.data || []));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = () => {
    if (location.pathname === "/home/reports") {
      dispatch(handleOpenCreateReport());
    } else if (matchGoalRoute) {
      dispatch(handleOpenCreateOkrModal(true));
    } else if (matchPeopleRoute) {
      if (
        plan?.remaining_team_members > 0 ||
        plan?.remaining_team_members === -1
      ) {
        dispatch(handleOpenTeamDrawer());
      } else {
        dispatch(handleOpenPlanModal());
      }
    } else if (location.pathname === "/home/people/teams") {
      dispatch(handleCreateTeamDrawer());
    } else {
      if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
        dispatch(handleOpenPlanModal());
      } else {
        showModal();
      }
    }
  };

  const optionsMenuList = [
    { value: "everywhere", label: "Everywhere" },
    { value: "goals", label: "Goals" },
    { value: "okrs", label: "Objective" },
    { value: "key_results", label: "Key Result" },
    { value: "employees", label: "Employee" },
    { value: "teams", label: "Teams" },
    { value: "reports", label: "Reports" },
  ];

  const handleSuggestionClick = (result) => {
    if (result?.team_id) {
      navigate("/home/people/teams");
      setShowResults(false);
    } else if (result?.okr_id) {
      dispatch(handleOpenOkrDrawer(result?.okr_id));
      setShowResults(false);
    } else if (result?.goal_id) {
      dispatch(handleOpenGoalDrawer(result?.goal_id));
      setShowResults(false);
    } else if (result?.key_id) {
      dispatch(handleOpenKeyresultDrawer(result?.key_id));
      setShowResults(false);
    } else if (result?.report_id) {
      navigate("/home/reports/" + result.report_id);
      setShowResults(false);
    } else if (result?.employee_id) {
      navigate("/home/people");
      setShowResults(false);
    }
  };

  const handleBlur = () => {
    setShowResults(false);
  };
  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="absolute flex items-center justify-between p-2  w-5/6">
      <div
        className="flex items-center text-slate-800 font-medium text-base cursor-pointer whitespace-nowrap pl-2 w-1/2 relative"
        onClick={() => {
          if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
            dispatch(handleOpenPlanModal());
          } else {
            setShowResults(false);
          }
        }}
      >
        <input
          type="text"
          style={{ position: "relative" }}
          className="w-full h-12 px-8 rounded-full text-gray-500 border pl-8"
          placeholder="Search for something"
          value={searchValue}
          onChange={handleSearchChange}
        />
        {showResults && (
          <div
            className="absolute top-16  w-full z-20 bg-white shadow-md"
            maxWidth="200px"
            onBlur={() => handleBlur()}
            ref={inputRef}
          >
            <CustomScrollbar>
              {results &&
                results.length > 0 &&
                results?.map((result, index) => (
                  <div
                    className="w-full flex flex-col border border-b p-3"
                    onBlur={() => handleBlur()}
                    maxWidth="200px"
                    onClick={() => handleSuggestionClick(result)}
                    key={index}
                  >
                    <div className="text-basic font-semibold">
                      {result?.team_id && <span>Team</span>}
                      {result?.report_id && <span>Report</span>}
                      {result?.goal_id && <span>Goal</span>}
                      {result?.okr_id && <span>Objective</span>}
                      {result?.key_id && <span>Key Result</span>}
                      {result?.employee_id && <span>Employee</span>}
                    </div>
                    {result?.team_id && (
                      <span className="w-full">{result?.team_name}</span>
                    )}
                    {result?.report_id && (
                      <span className="w-full">{result?.name}</span>
                    )}
                    {result?.goal_id && (
                      <span className="w-full">{result?.title}</span>
                    )}
                    {result?.okr_id && (
                      <span className="w-full">{result?.title}</span>
                    )}
                    {result?.key_id && (
                      <span className="w-full">{result?.title}</span>
                    )}
                    {result?.employee_id && (
                      <span className="w-full">
                        {result?.user_id?.username}
                      </span>
                    )}
                  </div>
                ))}
            </CustomScrollbar>
          </div>
        )}
        <div className="absolute right-2 flex justify-center items-center h-fit">
          <div className="flex justify-center items-center h-2/3 border-l border-gray-400 border-opacity-25">
            <div className="ml-2 relative w-28">
              <Menu isLazy>
                <MenuButton className="w-28" transition="all 0.2s">
                  <span
                    className="text-sm"
                    style={{ color: "#6b7280", opacity: "0.5" }}
                  >
                    {
                      optionsMenuList.find(
                        (item) => item?.value === selectedSearchOption
                      )?.label
                    }
                  </span>{" "}
                  <ChevronDownIcon />
                </MenuButton>
                <MenuList>
                  {optionsMenuList.map((item, index) => (
                    <React.Fragment key={index}>
                      <MenuItem
                        className="text-sm"
                        onClick={() => handleOptionSelect(item.value)}
                        _hover={{
                          backgroundColor: "#FEF5E3",
                          color: "inherit",
                        }}
                        _active={{
                          backgroundColor: "#FEF5E3",
                          color: "inherit",
                        }}
                        sx={{
                          "&:focus": {
                            backgroundColor: "#FEF5E3",
                            color: "inherit",
                          },
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    </React.Fragment>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
        <FiSearch className="absolute text-md left-5 top-1/2 transform -translate-y-1/2 text-gray-500" />
      </div>

      <ul className="grid grid-cols-4 items-center w-auto justify-center gap-2">
        <li className="col-span-2 flex justify-center items-center">
          <button
            onClick={handleClick}
            className={`text-white rounded-full py-1 min-w-fit px-6 h-12 bg-button hover:bg-button-hover second_tour third_tour
            sixth_tour seventh_tour
            }`}
          >
            {location.pathname === "/home/reports"
              ? "Create Report"
              : matchGoalRoute
              ? "Create Objective"
              : matchPeopleRoute
              ? "Invite Member"
              : location.pathname === "/home/people/teams"
              ? "Create Teams"
              : "Create Goals"}
          </button>
        </li>
        <Popover
          isLazy
          placement="bottom-start"
          isOpen={notificationOpen}
          onClose={handleNotificationOpen}
        >
          <PopoverTrigger>
            <li
              className="relative col-span-1"
              onClick={handleNotificationOpen}
            >
              <div className="flex items-center justify-center  transition-colors cursor-pointer">
                <span>
                  <svg
                    width="27"
                    height="34"
                    viewBox="0 0 27 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 28.3333V25H3.33333V13.3333C3.33333 11.0278 4.02778 8.97917 5.41667 7.1875C6.80556 5.39583 8.61111 4.22222 10.8333 3.66667V2.5C10.8333 1.80556 11.0764 1.21528 11.5625 0.729167C12.0486 0.243056 12.6389 0 13.3333 0C14.0278 0 14.6181 0.243056 15.1042 0.729167C15.5903 1.21528 15.8333 1.80556 15.8333 2.5V3.66667C18.0556 4.22222 19.8611 5.39583 21.25 7.1875C22.6389 8.97917 23.3333 11.0278 23.3333 13.3333V25H26.6667V28.3333H0ZM13.3333 33.3333C12.4167 33.3333 11.6319 33.0069 10.9792 32.3542C10.3264 31.7014 10 30.9167 10 30H16.6667C16.6667 30.9167 16.3403 31.7014 15.6875 32.3542C15.0347 33.0069 14.25 33.3333 13.3333 33.3333Z"
                      fill="#B4C5CE"
                    />
                  </svg>
                </span>
                {count !== 0 && (
                  <div
                    className="absolute top-0 flex items-center justify-center transform translate-x-2 p-0.2 text-center font-semibold text-white text-xs bg-red-500 rounded-full"
                    style={{
                      width: `${13 + 4 * (count.toString().length - 1)}px`,
                      height: `${13 + 4 * (count.toString().length - 1)}px`,
                    }}
                  >
                    {count}
                  </div>
                )}
              </div>
            </li>
          </PopoverTrigger>
          <Portal>
            <PopoverContent borderColor="white">
              <div>
                <NotificationModal
                  handleNotificationOpen={handleNotificationOpen}
                  count={count}
                  divRef={divRef}
                  data={data}
                  handleNotificationClick={handleNotificationClick}
                  deleteAllnotification={deleteAllnotification}
                  handleDeleteNotification={handleDeleteNotification}
                />
              </div>
            </PopoverContent>
          </Portal>
        </Popover>
        <li className="col-span-1 flex items-center justify-center transition-colors cursor-pointer">
          <Dropdown menu={{ items }} trigger={["click"]}>
            <button className="transition-colors rounded p-2">
              <div
                className="w-8 h-8 border  border-black rounded-full flex items-center justify-center text-white relative"
                style={{
                  backgroundColor: currentUser?.profile_image
                    ? "initial"
                    : getAvatarColor(currentUser?.username),
                }}
                size={18}
                onClick={handleMenuOpen}
              >
                {currentUser?.profile_image ? (
                  <img
                    src={currentUser.profile_image}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                      display: "block",
                    }}
                  />
                ) : currentUser?.username ? (
                  getInitials(currentUser.username)
                ) : (
                  "NA"
                )}
              </div>
            </button>
          </Dropdown>
        </li>
      </ul>

      <Modal
        open={openModal}
        footer={null}
        onCancel={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SwitchAccount handleCompanyChange={handleCompanyChange} />
      </Modal>
      <ProfileModal
        profileModal={profileModal}
        setProfileModal={setProfileModal}
      />
      <Modal
        open={okrSlice.openCreateGoalModal}
        onCancel={() => {
          setformDatanew((prevFormData) => ({
            ...prevFormData,
            session: "",
            title: "",
            owner: [],
            description: "",
          }));
          dispatch(handleCloseCreateGoalModal({ isOpen: false }));
        }}
        onOk={saveData}
        okText="Create Goal"
        confirmLoading={createGoalLoading}
      >
        <CreateGoalModal
          formData={formDatanew}
          setformData={setformDatanew}
          changeHandeler={changeHandeler}
          titleError={titleError}
          ownerError={ownerError}
          sessionError={sessionError}
          setTitleError={setTitleError}
          setOwnerError={setOwnerError}
          setSessionError={setSessionError}
        />
      </Modal>
      <div
        className={`fixed bottom-4 right-4 z-50 ${
          toast.error ? "block" : "hidden"
        }`}
      >
        <ToastContainer />
      </div>
    </div>
  );
};

export default SearchAndButton;
