import {  createSlice } from "@reduxjs/toolkit";


const initalState = {
  okrs: [],
  Key_results: [],
  openCreateOkrModal: false,
  openCreateKeyResultModal: false,
  openOkrDrawer: null,
  openKeyResultsDrawer: false,
  openCreateGoalModal: false,
  formData: null,
  currentOkrData: null,
  session: null,
  updateKeyResultModal: false,
  openGoalDrawer: false,
  openMoveGoalModal: false,
  openMoveOkrModal: false,
  openMoveKeyResultModal: false,
  openCopyModal: false,
  openTeamDrawer: false,
  openCreateTeamDrawer: false,
  parentDropdownItems: [],
  openCreateReport: false,
};


const Slice = createSlice({
  name: "okr",
  initialState: initalState,
  reducers: {
    handleOpenCreateOkrModal: (state, action) => {
      state.openCreateOkrModal = action.payload;
    },
    handleCloseCreateOkrModal: (state, action) => {
      state.openCreateOkrModal = false;
    },
    handleOpenOkrDrawer: (state, action) => {
      state.openOkrDrawer = action.payload;
    },
    handleCloseOkrDrawer: (state, action) => {
      state.openOkrDrawer = null;
    },
    handleOpenCreateReport: (state, action) => {
      state.openCreateReport = true;
    },
    handleCloseCreateReport: (state, action) => {
      state.openCreateReport = false;
    },
    handleOpenCreateKeyResultModal: (state, action) => {
      state.openCreateKeyResultModal = action.payload;
    },
    handleCloseCreateKeyResultModal: (state, action) => {
      state.openCreateKeyResultModal = false;
    },
    handleOpenKeyresultDrawer: (state, action) => {
      state.openKeyResultsDrawer = action.payload;
    },
    handleCloseKeyresultDrawer: (state, action) => {
      state.openKeyResultsDrawer = false;
    },
    handleOpenCreateGoalModal: (state, action) => {
      const { isOpen } = action.payload;
      state.openCreateGoalModal = isOpen;
    },
    handleCloseCreateGoalModal: (state, action) => {
      const { isOpen } = action.payload;
      state.openCreateGoalModal = isOpen;
    },
    handleCurrentOkrData: (state, action) => {
      state.currentOkrData = action.payload;
    },
    handleUpdateSession: (state, action) => {
      state.session = action.payload;
    },
    handleOpenUpdateKeyResultModal: (state, action) => {
      state.updateKeyResultModal = action.payload;
    },
    handleCloseUpdateKeyResultModal: (state, action) => {
      state.updateKeyResultModal = false;
    },
    handleOpenGoalDrawer: (state, action) => {
      state.openGoalDrawer = action.payload;
    },
    handleOpenTeamDrawer: (state, action) => {
      state.openTeamDrawer = true;
    },
    handleCloseTeamDrawer: (state, action) => {
      state.openTeamDrawer = false;
    },
    handleCreateTeamDrawer: (state, action) => {
      state.openCreateTeamDrawer = true;
    },
    handleCreateCloseTeamDrawer: (state, action) => {
      state.openCreateTeamDrawer = false;
    },
    handleCloseGoalDrawer: (state, action) => {
      state.openGoalDrawer = false;
    },
    handleOpenMoveGoalModal: (state, action) => {
      state.openMoveGoalModal = action.payload;
    },
    handleCloseMoveGoalModal: (state, action) => {
      state.openMoveGoalModal = false;
    },
    handleOpenMoveOkrModal: (state, action) => {
      state.openMoveOkrModal = action.payload;
    },
    handleCloseMoveOkrModal: (state, action) => {
      state.openMoveOkrModal = false;
    },
    handleOpenMoveKeyResultModal: (state, action) => {
      state.openMoveKeyResultModal = action.payload;
    },
    handleCloseMoveKeyResultModal: (state, action) => {
      state.openMoveKeyResultModal = false;
    },
    handleOpenCopyGoalModal: (state, action) => {
      state.openCopyGoalModal = action.payload;
    },
    handleCloseCopyGoalModal: (state, action) => {
      state.openCopyGoalModal = false;
    },
    handleOpenCopyOkrModal: (state, action) => {
      state.openCopyOkrModal = action.payload;
    },
    handleCloseCopyOkrModal: (state, action) => {
      state.openCopyOkrModal = false;
    },
    handleOpenCopyKeyResultModal: (state, action) => {
      state.openCopyKeyResultModal = action.payload;
    },
    handleCloseCopyKeyResultModal: (state, action) => {
      state.openCopyKeyResultModal = false;
    },
    handleParentDropdownChanges: (state, action) => {
      state.parentDropdownItems = action.payload;
    },
  },
});

export const {
  handleOpenCreateOkrModal,
  handleParentDropdownChanges,
  handleCloseCreateOkrModal,
  handleOpenOkrDrawer,
  handleCloseOkrDrawer,
  handleOpenCreateKeyResultModal,
  handleCloseCreateKeyResultModal,
  handleOpenKeyresultDrawer,
  handleCloseKeyresultDrawer,
  handleOpenCreateGoalModal,
  handleCloseCreateGoalModal,
  handleCurrentOkrData,
  handleUpdateSession,
  handleCloseUpdateKeyResultModal,
  handleOpenUpdateKeyResultModal,
  handleOpenGoalDrawer,
  handleOpenCreateReport,
  handleCloseCreateReport,
  handleCloseGoalDrawer,
  handleOpenTeamDrawer,
  handleCloseTeamDrawer,
  handleOpenCopyModal,
  handleCloseCopyModal,
  handleCreateTeamDrawer,
  handleCreateCloseTeamDrawer,
  handleOpenMoveGoalModal,
  handleCloseMoveGoalModal,
  handleOpenMoveOkrModal,
  handleCloseMoveOkrModal,
  handleOpenMoveKeyResultModal,
  handleCloseMoveKeyResultModal,
  handleOpenCopyGoalModal,
  handleCloseCopyGoalModal,
  handleOpenCopyOkrModal,
  handleCloseCopyOkrModal,
  handleOpenCopyKeyResultModal,
  handleCloseCopyKeyResultModal,
} = Slice.actions;

export default Slice.reducer;
