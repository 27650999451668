import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import axios from "axios";
import { baseURL } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { EditorState } from "draft-js";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import { handleUpdatePlan } from "../../../stores/slices/userAuthSlice";



const TeamModal = ({
  handleCancel,
  handleOk,
  formData,
  setformData,
  changeHandeler,
  fetchData,
}) => {
  const [owner, setOwner] = useState([]);
  const [ownerError, setOwnerError] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const currentCompany = useSelector((state) => state.user?.currentCompany);
  const dispatch = useDispatch();

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/employee_details/", {
          params: {
            company_id: company_id,
          },
        });
        setOwner(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [companyData, setCompanyData] = useState(null);

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await axios.get(baseURL + "/subscription/", {
        params: {
          user_id: currentCompany?.user_id,
          company_id: currentCompany?.company_id,
        },
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(baseURL + "/create-team/", {
        team_name: formData?.name,
        manager_id: formData?.manager,
        company_id: currentUser?.employees[currentCompany]?.company_id,
        login_user_id : currentUser?.employees[currentCompany]?.user_id,
        user_ids: formData?.members,
      });
      getCurrentPlanData();
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
      fetchData();
      setformData({
        owner: "",
        manager: null,
        name: "",
      });
    }

    setLoading(false);
  };


  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  return (
    <div>
      <form
        className="'w-full flex flex-col py-3 px-3 gap-y-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col items-start w-full gap-y-2">
          <h1 className="p-1 text-3xl font-bold text-black">Create team</h1>
          <p className="p-1 text-gray-600">
            All required fields are marked with an asterisk (*).
          </p>
        </div>

        {/* {showBelowDiv && ( */}
        <div className="w-full grid grid-cols-6 gap-x-6 py-2 my-3 gap-y-6 items-center max-h-[350px] overflow-y-auto">
          <label className="col-span-2 text-sm">Name*</label>

          <div className="col-span-4 ">
            <input
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
              type="text"
              name="name"
              id="name"
              onChange={changeHandeler}
              value={formData.name}
              placeholder=""
              required
            />
          </div>

          <label className="col-span-2 text-sm">Manager</label>

          <div className="col-span-4 ">
            <Select
              name="manager"
              id="manager"
              onChange={(val) => {
                setformData({ ...formData, manager: val });
                setOwnerError(false);
              }}
              value={formData.manager}
              required
              className="w-full "
              placeholder="Select Manager"
            >
              {owner.map((owner, index) => {
                return (
                  <Select.Option value={owner?.user_id} key={index}>
                    {/* {owner?.username} */}
                    <div className="flex items-center gap-x-2">
                      <div
                        className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                        style={{
                    backgroundColor: owner?.profile_image
                      ? "initial"
                      : getAvatarColor(owner?.username),
                  }}
                      >
                        {owner?.profile_image ? (
                          <img
                            src={owner?.profile_image}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                              display: "block",
                            }}
                          />
                        ) : (
                          getInitials(owner?.username)
                        )}
                      </div>
                      <span>{owner?.username}</span>
                    </div>
                  </Select.Option>
                );
              })}

              {/* calling parent form  */}
            </Select>
          </div>

          <label className="col-span-2 text-sm">Members</label>

          <div className="col-span-4 ">
            <Select
              name="members"
              id="members"
              onChange={(val) => {
                setformData({ ...formData, members: val });
                setOwnerError(false);
              }}
              value={formData.members}
              mode="multiple"
              required
              className="w-full "
              placeholder="Select Member"
            >
              {owner.map((owner, index) => {
                return (
                  <Select.Option value={owner?.user_id} key={index}>
                    <div className="flex items-center gap-x-2">
                      <div
                        className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                        style={{
                    backgroundColor: owner?.profile_image
                      ? "initial"
                      : getAvatarColor(owner?.username),
                  }}
                      >
                        {owner?.profile_image ? (
                          <img
                            src={owner?.profile_image}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                              display: "block",
                            }}
                          />
                        ) : (
                          getInitials(owner?.username)
                        )}
                      </div>
                      <span>{owner?.username}</span>
                    </div>
                  </Select.Option>
                );
              })}

            </Select>
          </div>

          <div className="flex gap-5">
            <Button
              loading={loading}
              className="bg-button hover:shadow-lg text-white"
              onClick={handleOk}
              htmlType="submit"
            >
              Create Team
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TeamModal;
