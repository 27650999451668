import {  Select, Tooltip, TreeSelect } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import { Sessions } from "../../../utils/Constants";
import { BiGlobe } from "react-icons/bi";
import { GoGoal } from "react-icons/go";

const CreateGoalModal = ({
  formData,
  setformData,
  titleError,
  sessionError,
  ownerError,
  changeHandeler,
  setTitleError,
  setSessionError,
  setOwnerError,
}) => {
  const [owner, setOwner] = useState([]);
  const [parent, setParent] = useState([]);


  const [parentDropdownItems, setParentDropdownItems] = useState([]);
  const okrSlice = useSelector((state) => state.okr);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const getParentData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await axios.get(baseURL + "/assignparents/", {
        params: {
          company_id: currentEmployee?.company_id,
          session: okrSlice?.session,
        },
      });
      const tempParentTree = response.data?.map((goal) => ({
        label: (
          <p className="flex gap-x-2 items-center">
            <button
              className="rounded p-1"
              style={{
                background:
                  "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
              }}
            >
              <GoGoal
                className="text-md"
                style={{
                  color: "#B91C1C",
                  width: "18px",
                  height: "18px",
                }}
              />
            </button>
            {goal?.title}
          </p>
        ),
        value: goal?.goal_id + " " + "goal",
        type: "goal",
       
      }));
      setParentDropdownItems(tempParentTree);
    } catch (error) {}
  };

  useEffect(() => {
    getParentData();
  }, [okrSlice?.openCreateGoalModal]);

  useEffect(() => {
    setformData((prevFormData) => ({
      ...prevFormData,
      session : null,
      title: "",
      description: "",
      owner: [],
    }));
  }, [okrSlice?.openCreateGoalModal]);

  console.log("formData",formData);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/assignowners/", {
          params: {
            company_id: company_id,
          },
        });
        setOwner(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  


  return (
    <div className="">
      <h1 className="p-2 m-2 text-3xl font-bold text-black">Create Goal</h1>
      <p className="p-2 m-2 text-gray-600">
        All required fields are marked with an asterisk (*).
      </p>

      <div className="bg-green">
        <form action="">
          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Session*
            </label>
            <br />

            <div className="w-full">
              <div className="flex items-center gap-x-2">
                <Select
                  name="session"
                  id="session"
                  onChange={(value) => {
                    setformData({ ...formData, session: value });
                    console.log("session",value);
                    setSessionError(false);
                  }}
                  required
                  // mode="multiple"
                  className="w-full"
                  value={formData?.session}
                  placeholder="Add Session"
                >
                  {Sessions.map((session, index) => {
                    return (
                      <Select.Option value={session.value} key={index}>
                        {session.label}
                      </Select.Option>
                    );
                  })}
                </Select>

                <Tooltip
                  arrow={false}
                  placement="rightTop"
                  title="You can change the session of the Goal"
                >
                  <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                </Tooltip>
              </div>
              {sessionError && (
                <p className="mt-1 text-red-500">Session is required</p>
              )}
            </div>
          </div>

          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Title*
            </label>
            <br />
            <div className="w-full">
              <div className="flex items-center gap-x-2">
                <input
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
                  type="text"
                  name="title"
                  id="title"
                  onChange={changeHandeler}
                  value={formData.title}
                  maxLength={100}
                  placeholder="Enter a title"
                  required
                />

                <Tooltip
                  arrow={false}
                  placement="rightTop"
                  title="Consider a title no longer than 70 characters. it will make Goal clear, punchy and easy to remember."
                >
                  <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                </Tooltip>
              </div>
              {titleError && (
                <p className="mt-1 text-red-500">Title is required</p>
              )}
            </div>
          </div>

          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Owner*
            </label>
            <br />

            <div className="w-full">
              <div className="flex items-center gap-x-2">
                <Select
                  name="=owner"
                  id="owner"
                  onChange={(val) => {
                    setformData({ ...formData, owner: val });
                    setOwnerError(false);
                  }}
                  value={formData.owner}
                  mode="multiple"
                  required
                  className="w-full "
                  placeholder="Select Owner"
                >
                  {owner.map((owner, index) => {
                    return (
                      <Select.Option
                        value={owner?.id + " " + owner?.type}
                        key={index}
                      >
                        <div className="flex items-center gap-x-2">
                          {owner?.type === "user" ? (
                            <div
                              className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                              style={{
                                backgroundColor: owner?.profile_image
                                  ? "initial"
                                  : getAvatarColor(owner?.name),
                              }}
                              size={18}
                            >
                              {owner?.profile_image ? (
                                <img
                                  src={owner?.profile_image}
                                  alt="Profile"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    display: "block",
                                  }}
                                />
                              ) : (
                                getInitials(owner.name)
                              )}
                            </div>
                          ) : (
                            <span className="p-1 bg-gray-600 rounded-full">
                              <BiGlobe className="text-gray-300 text-xs" />
                            </span>
                          )}
                          <span>{owner.name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}

                </Select>

                <Tooltip
                  arrow={false}
                  placement="rightTop"
                  className="text-gray-500"
                  title="Goal can be assigned to multiple people and/or teams"
                >
                  <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                </Tooltip>
              </div>
              {ownerError && (
                <p className="mt-1 text-red-500">Owner is required</p>
              )}
            </div>
          </div>

          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Parent
            </label>
            <br />

            <div className="w-full ">
              <div className="flex items-center gap-x-2">
                <TreeSelect
                  showSearch
                  className="w-full"
                  value={formData?.parent}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Assign Parent"
                  allowClear
                  onChange={(newVal) => { 
                    setformData({ ...formData, parent_val: newVal });
                  }}
                  treeData={parentDropdownItems}
                />
                <Tooltip
                  arrow={false}
                  placement="rightTop"
                  title="Other goals/objective/key results can be assigned as parent to this goal"
                >
                  <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Description
            </label>
            <br />
            <div className="w-full">
              <div className="flex items-center gap-x-2">
                <textarea
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
                  name="description"
                  id="description"
                  onChange={changeHandeler}
                  value={formData.description}
                  placeholder="Enter description"
                  required
                  maxLength={600}
                />

                <Tooltip
                  arrow={false}
                  placement="rightTop"
                  title="Consider a description no longer than 150 characters. it will make Goal clear, punchy and easy to remember."
                >
                  <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                </Tooltip>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGoalModal;
