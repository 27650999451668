import React from "react";
import { BsChatText } from "react-icons/bs";
import { useState } from "react";
import { IoSend } from "react-icons/io5";
import axios from "axios";
import { baseURL } from "../../../utils/config";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CommentCard from "./CommentCard";
import MentionInput from "./CustomMentionInput";

const OkrComments = ({ okr_id }) => {
  const [editState, setEditState] = useState("");

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const [allComments, setAllComments] = useState([]);
  const [editedComment, setEditedComment] = useState("");
  const [mention, setMentions] = useState([]);
  const getAllComments = async () => {
    try {
      const res = await axios.get(baseURL + "/comments/", {
        params: {
          okr_id: okr_id,
        },
      });
      setAllComments(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (okr_id) getAllComments();
  }, [okr_id]);
  const handleEditComment = async (commentData, currentEditorState) => {
    if (!currentEditorState) return;
    try {
      const res = await axios.put(baseURL + "/comments/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        login_user_id: currentUser?.employees[user?.currentCompany]?.user_id,
        comment_id: commentData.comment_id,
        text: editState,
        okr_id: okr_id,
      });
    } catch (error) {
      console.log(error);
    } finally {
      getAllComments();
      setEditedComment("");
    }
  };

  const handleDeleteComment = async (comment_id) => {
    try {
      const res = await axios.delete(baseURL + "/comments/", {
        params: {
          comment_id: comment_id,
        },
      });
      getAllComments();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/assignowners/", {
          params: {
            company_id: company_id,
          },
        });
        setMentions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await axios.post(baseURL + "/comments/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        user_id: currentUser?.employees[user?.currentCompany]?.user_id,
        login_user_id: currentUser?.employees[user?.currentCompany]?.user_id,
        text: editState,
        okr_id: okr_id,
      });
      setEditState("");
      getAllComments();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center justify-between border-b-2">
        <div className="flex p-2 gap-x-2">
          <h4 className="flex items-center text-xl text-gray-800 gap-x-2">
            <span className="p-0.5 bg-gray-200 rounded">
              <BsChatText className="text-xs font-bold text-gray-600" />
            </span>
            <span>Comments</span>
          </h4>
        </div>
      </div>
      <div className=" grid gap-4">
        {allComments?.map((comment) => (
          <CommentCard
            key={comment?.comment_id}
            commentData={comment}
            handleDeleteComment={handleDeleteComment}
            isCommentEditing={comment?.comment_id === editedComment}
            setEditedComment={setEditedComment}
            handleEditComment={handleEditComment}
          />
        ))}
      </div>
      <form className="flex w-full items-center gap-2 " onSubmit={handleSubmit}>
        <MentionInput
          setEditState={setEditState}
          editState={editState}
          mention={mention}
          setMentions={setMentions}
        />
        <button
          type="submit"
          className="p-3 bg-primaryBg/80 text-white rounded-full hover:bg-primaryBg  transition-all"
          disabled={loading || !editState}
        >
          <IoSend />
        </button>
      </form>
    </div>
  );
};

export default OkrComments;
