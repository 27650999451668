import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

const Card = ({ icon, title, subtitle, desc, color, textcolor }) => {
  return (
    <Box
      p={4}
      rounded="md"
      cursor="pointer"
      flexDir="column"
      h="28"
      bg={color}
      minW={200}
    >
      <Flex justify="space-between" alignItems="center">
        <Flex alignItems={"center"}>
          <Text
            fontSize="3xl"
            fontFamily="Poppins"
            fontWeight="semibold"
            textTransform="capitalize"
          >
            {title}
          </Text>

          {subtitle && (
            <Text fontSize="xl" fontWeight="semibold">
              / {subtitle}
            </Text>
          )}
        </Flex>
        {icon}
      </Flex>
      <Text fontFamily="Poppins" fontSize="md" color={textcolor}>
        {desc}
      </Text>
    </Box>
  );
};

export default Card;
