// reducers/slice1.js
import { createSlice } from "@reduxjs/toolkit";


const userAuthSlice = createSlice({
  name: "userslice",
  initialState: {
    email: null,
    currentUser: null,
    authLoader: false,
    authenticated: false,
    failed: false,
    stepcheck: 0,
    currentCompany: 0,
    AnsList: [],
    emailverified: false,
    PlanModal: false,
    currentPlan: {

    }
  },


  reducers: {
    updateUserSignup: (state, action) => {
      state.currentUser = action.payload;
      state.currentCompany = 0
      state.authenticated = true;
      localStorage.setItem('startTour', true);
    },
    updateUserLogin: (state, action) => {
      state.currentUser = action.payload;
      state.currentCompany = 0
      state.authenticated = true;
      // localStorage.setItem('startTour', true);
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateEmailVerification: (state, action) => {
      state.emailverified = action.payload;
      state.email = null;
    },
    logout: (state) => {
      state.authenticated = false;
      state.currentUser = null;
      state.email = null;
      state.emailverified = false;
    },
    updateStepCheck: (state, action) => {
      state.stepcheck = action.payload;
    },
    updateAnsList: (state, action) => {
      state.AnsList = action.payload;
    },
    handleUpdateCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    handleUpdatePlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    handleOpenPlanModal: (state, action) => {
      state.PlanModal = true;
    },
    handleClosePlanModal: (state, action) => {
      state.PlanModal = false;
    },
  },
  
});




// Export slice1 reducer and actions
export const { updateAnsList } = userAuthSlice.actions;
export const { updateStepCheck } = userAuthSlice.actions;
export const { updateUserSignup, updateUserLogin,updateEmail,updateEmailVerification } = userAuthSlice.actions;
export const { logout } = userAuthSlice.actions;
export const { handleUpdateCurrentCompany, handleUpdatePlan,handleOpenPlanModal,handleClosePlanModal } = userAuthSlice.actions;
export default userAuthSlice.reducer;


