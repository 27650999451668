import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    drafts: []
};

const Slice = createSlice({
    name: 'drafts',
    initialState: initalState,
    reducers: {
        handleUpdateDraftSlice: (state, action) => {

            state.drafts = action.payload
        }

    }
})


export const { handleUpdateDraftSlice } = Slice.actions

export default Slice.reducer;


