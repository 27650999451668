import React from 'react'
import KeyresultsReportTable from './KeyresultsReportTable'
import OkrReportsTable from './OkrReportTable'
import { Spin } from 'antd'
import GaolReportsTable from './GoalReportTable'
import TeamReportsTable from './TeamReportTable'

const ReportController = ({ type, keyResultReports, okrReports,goalReports,teamReports, loading }) => {

    if (loading)
        return  <div className=" h-full  flex justify-center items-center mx-auto">
        <img src="/assets/images/Group 3.svg" />
    </div>
    else if (keyResultReports)
        return <KeyresultsReportTable keyResultReports={keyResultReports} />
    else if (okrReports)
        return <OkrReportsTable okrReports={okrReports} />
    else if(goalReports)
        return <GaolReportsTable goalReports={goalReports} />
    else if(teamReports)
        return <TeamReportsTable teamReports={teamReports} />

}

export default ReportController