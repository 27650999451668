import React from "react";
import { Avatar, Progress } from "antd";
import {
  getAvatarColor,
  getInitials,
} from "../../utils/helpers";


const TeamReportsTable = ({ teamReports }) => {
  
  return (
    <div className="w-full">
      <div className="grid grid-cols-5 w-full p-4 sticky bg-header text-white">
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Name</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Members</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Manager</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Progress</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Description</h3>
        </div>
      </div>
      <div className="">
        {teamReports?.map((item, i) => (
          <div
            className={`grid grid-cols-5 w-full px-2  border border-b h-auto py-4 text-basic font-medium text-base`}
            key={i}
          >
            <div className="flex items-center ml-5 justify-start gap-x-2 col-span-1 w-full px-2">
              <p>{item?.team_name}</p>
            </div>

            <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
              <Avatar.Group
                maxCount={2}
                size={"small"}
                maxPopoverTrigger="click"
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  display: "flex",
                  flexDirection: "column !important",
                }}
              >
                {item?.employees?.map((employee, index) => (
                  <div
                  key={index}
                    className="w-5 h-5 text-sm border border-black rounded-full flex items-center  text-xs justify-center text-white relative"
                    style={{
                      backgroundColor: employee?.profile_image
                        ? "initial"
                        : getAvatarColor(employee?.username),
                    }}
                    size={18}
                  >
                    {employee?.profile_image ? (
                      <img
                        src={employee?.profile_image}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                          display: "block",
                        }}
                      />
                    ) : (
                      getInitials(employee?.username)
                    )}
                  </div>
                ))}
              </Avatar.Group>
            </div>
            <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
              <Avatar.Group
                maxCount={2}
                size={"small"}
                maxPopoverTrigger="click"
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  display: "flex",
                  flexDirection: "column !important",
                }}
              >
                {item?.manager?.map((employee, index) => (
                  <div
                  key={index}
                    className="w-5 h-5 text-sm border border-black rounded-full  text-xs flex items-center justify-center text-white relative"
                    style={{
                      backgroundColor: employee?.profile_image
                        ? "initial"
                        : getAvatarColor(employee?.username),
                    }}
                    size={18}
                  >
                    {employee?.profile_image ? (
                      <img
                        src={employee?.profile_image}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                          display: "block",
                        }}
                      />
                    ) : (
                      getInitials(employee?.username)
                    )}
                  </div>
                ))}
              </Avatar.Group>
            </div>
            <div className="flex items-center justify-center gap-x-2 col-span-1">
              {/* {item?.overall_gain}% */}
              <Progress
                type="circle"
                size={50}
                percent={item?.progress}
                strokeWidth={10}
                strokeColor={
                  item?.progress >= 0 && item?.progress <= 29
                    ? "#FF2934"
                    : item?.progress >= 30 && item?.progress <= 49
                    ? "#FF8400"
                    : item?.progress >= 50 && item?.progress <= 69
                    ? "#FFF000"
                    : "#53DC0D"
                }
                trailColor="#f0f0f0"
                format={(percent) => (
                  <span style={{ fontWeight: "medium", color: "black" }}>
                    {percent}%
                  </span>
                )}
              />
            </div>
            <div
              className="flex p-2 items-start gap-x-2 col-span-1"
              style={{
                border: "0.96px solid rgba(168, 175, 178, 0.72)",
                borderRadius: "7.7px",
              }}
            >
              {item?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamReportsTable;
