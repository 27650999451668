// store.js
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import userAuthSlice from "./slices/userAuthSlice";
import okrSlice from "./slices/okrSlice";
import DraftSlice from "./slices/draftSlice";



const rootReducer = combineReducers({
  user: userAuthSlice,
});

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false,
// });

const persistConfigSlice1 = {
  key: "user",
  storage,
};

const persistedReducerSlice1 = persistReducer(
  persistConfigSlice1,
  userAuthSlice
);

const store = configureStore({
  reducer: {
    user: persistedReducerSlice1,
    okr: okrSlice,
    draft: DraftSlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };
