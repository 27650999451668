import React, { useEffect, useState } from "react";
import StrategyWidgits from "../../components/Insights/Strategy/StrategyWidgits";
import StrategicReportBox from "../../components/Insights/Strategy/StrategicReportBox";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../utils/config";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/helpers";
import DashboardHeader from "../../components/global/DashboardHeader";

const StrategicReport = () => {
  const navigate = useNavigate();
  const [teamsData, setTeamsData] = useState([]);
  const [reportData, setReportData] = useState({
    keyResult: "",
    overallGainAvg: "",
    avgConfidence: "",
  });
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const plan = user?.currentPlan;

  if (!plan?.free_trial_status && !plan?.features?.Strategic_insights) {
    navigate("/home/billing");
  }

  const getReportData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const res = await axios.get(baseURL + "/insights/stategic-report/", {
        params: {
          company_id: currentEmployee?.company_id,
        },
      });
      // console.log(res.data)
      setTeamsData(res?.data?.teams);
      setReportData({
        keyResult: res?.data?.total_key_result,
        overallGainAvg: res?.data?.overall_gain_avg,
        avgConfidence: res?.data?.average_confidence,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (plan?.free_trial_status || plan?.features?.Strategic_insights) {
      getReportData();
    }
  }, []);

  return (
    <div className="relative h-screen overflow-y-scroll p-4">
      <DashboardHeader
        dashboardTitle="Insights"
        LinkLocation={"/home/insight/strategic"}
        ClickLink={"Insight / Strategic Reports"}
      />
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F0F5FC",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <div className="p-4 h-full bg-white rounded-lg overflow-y-auto">
          <StrategyWidgits reportData={reportData} />
          <div className="my-4 grid gap-8">
            <div className="flex gap-4 ">
              <div className="col-span-9">
                <h2
                  className="text-xl font-medium py-2"
                  style={{
                    color: "#4195B1",
                  }}
                >
                  Team Contributions
                </h2>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              <StrategicReportBox teamsData={teamsData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicReport;
