import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCloseCreateGoalModal,
  handleOpenCreateGoalModal,
} from "../../stores/slices/okrSlice";
import TableView from "../../components/Okrs/Goals/TableView";
import { Modal } from "antd";
import CreateGoalModal from "../../components/Okrs/Goals/CreateGoalModal";
import axios from "axios";
import { baseURL } from "../../utils/config";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import { handleUpdatePlan } from "../../stores/slices/userAuthSlice";
import { useNavigate } from "react-router-dom";

const AllGoals = () => {
  const navigate= useNavigate();
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      // setTimeout(() => {
      // setLoader(false);
      setState({ run: true, stepIndex: 1 });
      // }, 1200);
    }
  });

  const [titleError, setTitleError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const plan = user?.currentPlan;
  const okrSlice = useSelector((state) => state.okr);
  const dispatch = useDispatch();
  const [owners, setOwners] = useState();
  const [goals, setGoals] = useState();
  const [goalsLoading, setGoalsLoading] = useState(false);
  const [formDataFilter, setformDatafilter] = useState({
    session: null,
    goal_name: "",
    goal_owner: [],
    okr_name: "",
    okr_owner: [],
    title: "",
    progress: {
      comparison: "equal_to",
      value: null,
    },
    lastUpdated: {
      comparison: "before",
      value1: null,
      value2: null,
    },
  });
  const [formData, setformData] = useState({
    session: okrSlice?.session || null,
    title: null,
    owner: [],
    description:null,
  });
  useEffect(() => {
    setformData({
      session: okrSlice?.session || null,
      title: null,
      owner: [],
      description: null,
    });
  }, [okrSlice?.session]);

  const [createGoalLoading, setCreateGoalLoading] = useState(false);
  const getGoals = async () => {
    let user_ids1 = [];
    let team_ids1 = [];
    formDataFilter?.goal_owner?.forEach((item) => {
      let typeArr = item.split(" ");
      if (typeArr[1] === "user") {
        user_ids1.push(typeArr[0]);
      } else {
        team_ids1.push(typeArr[0]);
      }
    });
    let user_ids2 = [];
    let team_ids2 = [];
    formDataFilter?.okr_owner?.forEach((item) => {
      let typeArr = item.split(" ");
      if (typeArr[1] === "user") {
        user_ids2.push(typeArr[0]);
      } else {
        team_ids2.push(typeArr[0]);
      }
    });
    try {
      setGoalsLoading(true);
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      let lastUpdatedValue = "";
      if (formDataFilter?.lastUpdated?.comparison === "never") {
        lastUpdatedValue = true;
      } else if (formDataFilter?.lastUpdated?.comparison === "between") {
        const first = formDataFilter?.lastUpdated?.value1?.$d?.toISOString();
        const second = formDataFilter?.lastUpdated?.value2?.$d?.toISOString();
        lastUpdatedValue = `${first},${second}`;
      } else {
        lastUpdatedValue =
          formDataFilter?.lastUpdated?.value1?.$d?.toISOString();
      }
      const params = {
        goal_name: formDataFilter?.goal_name,
        okr_name: formDataFilter?.okr_name,
        okr_owner: { user_ids: user_ids2, team_ids: team_ids2 },
        goal_owner: { user_ids: user_ids1, team_ids: team_ids1 },
        progress: {
          [formDataFilter?.progress?.comparison]:
            formDataFilter?.progress?.value,
        },
        last_updated: {
          [formDataFilter?.lastUpdated?.comparison]: lastUpdatedValue,
        },
      };
     const session = (okrSlice && okrSlice.session !== undefined) ? okrSlice?.session : 
     ((formDataFilter && formDataFilter?.session !== undefined) ? formDataFilter?.session : 
     0);
      const result = await axios.get(`${baseURL}/goal/`, {
        params: {
          company_id: company_id,
          session: session,
          fields: JSON.stringify(params),
        },
      });
      setGoals(result.data);
      setGoalsLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) {
        setGoals([]);
      }
      console.log(error);
    }
  };
  useEffect(() => {
    getGoals();
  }, [okrSlice?.session, formDataFilter]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/assignowners/", {
          params: {
            company_id: company_id,
          },
        });
        setOwners(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
    navigate("/home/billing");
  }

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "title") {
      //  setTitleError(false);

      if (value && value.length <= 70) {
        setTitleError(false);
      }
    }
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }


  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await axios.get(baseURL + "/subscription/", {
        params: {
          user_id: currentCompany?.user_id,
          company_id: currentCompany?.company_id,
        },
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };


  const saveData = async (event) => {
    event.preventDefault();
    let user_id = [];
    let team_id = [];
    let parent;
    let parent_type;
    if (formData?.session === null) {  
      setSessionError(true);
      return;
    }
    if (!formData.title) {
      setTitleError(true);
      return;
    }
    if (!formData?.owner?.length) {
      setOwnerError(true);

      return;
    }
     else {
      formData?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    }
    if (formData?.parent_val) {
      const parentArr = formData?.parent_val.split(" ");
      parent = parentArr[0];
      parent_type = parentArr[1];
    }

    try {
      setCreateGoalLoading(true);

      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
        login_user_id = currentEmployee[user?.currentCompany]?.user_id;
      }

      const result = await axios.post(baseURL + "/goal/", {
        title: formData.title,
        user_id: user_id,
        team_id: team_id,
        description: formData.description,
        company_id: company_id,
        session: formData.session,
        parent: parent,
        parent_type: parent_type,
        login_user_id: login_user_id,
      });
      getCurrentPlanData();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "goal_created",
        message: "Goal created succesfully!.",
        id: result?.data?.goal_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      setformData((prevFormData) => ({
        ...prevFormData,
        session: null,
        title: null,
        owner: [],
        description: null,
      }));
    } catch (err) {
      console.log(err);
      setformData((prevFormData) => ({
        ...prevFormData,
        session: null,
        title: null,
        owner: [],
        description: null,
      }));
    } finally {
      getGoals();
      dispatch(handleCloseCreateGoalModal({ isOpen: false }));
      setCreateGoalLoading(false);
      setformData({
        session: okrSlice?.session,
        title: null,
        owner: [],
        description: null,
      });
    }
  };

  return (
    <div className="flex flex-col w-full h-screen overflow-y-hidden">
      <TableView
        goals={goals}
        goalsLoading={goalsLoading}
        owners={owners}
        formData={formDataFilter}
        setformData={setformDatafilter}
      />
      <Modal
        open={okrSlice.openCreateGoalModal}
        onCancel={() => {
          setformData((prevFormData) => ({
            ...prevFormData,
            session: null,
            title: null,
            owner: [],
            description: null,
          }));
          dispatch(handleCloseCreateGoalModal({ isOpen: false }));
        }}
        onOk={saveData}
        okText="Create Goal"
        confirmLoading={createGoalLoading}
      >
        <CreateGoalModal
          formData={formData}
          setformData={setformData}
          changeHandeler={changeHandeler}
          titleError={titleError}
          ownerError={ownerError}
          sessionError={sessionError}
          setTitleError={setTitleError}
          setOwnerError={setOwnerError}
          setSessionError={setSessionError}
        />
      </Modal>
    </div>
  );
};

export default AllGoals;
