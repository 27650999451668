// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.style_readOnly__H0sUy {
  border: none;
  width: 100%; 
}

.style_mentions__3EXr0 {
  margin: 1em 0 2em 0;
  cursor: default;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 6px;
}
.style_mentions__3EXr0:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

}


.style_mentions__suggestions__list__-qC\\+0 {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.style_mentions__suggestions__item__NSwWp {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.style_mentions__suggestions__item--focused__T1krB {
  background-color: #cee4e5;
}

.style_mentions__mention__GAk2c {
  background-color: #cee4e5;
  
}`, "",{"version":3,"sources":["webpack://./src/components/Okrs/Comments/style.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,qCAAqC;EACrC,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,2BAA2B;;AAE7B;;;AAGA;EACE,uBAAuB;EACvB,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,4CAA4C;AAC9C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;;AAE3B","sourcesContent":["\n.readOnly {\n  border: none;\n  width: 100%; \n}\n\n.mentions {\n  margin: 1em 0 2em 0;\n  cursor: default;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  padding: 6px;\n}\n.mentions:focus {\n  border: none !important;\n  outline: none !important;\n  box-shadow: none !important;\n\n}\n\n\n.mentions__suggestions__list {\n  background-color: white;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  font-size: 10pt;\n}\n\n.mentions__suggestions__item {\n  padding: 5px 15px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n}\n\n.mentions__suggestions__item--focused {\n  background-color: #cee4e5;\n}\n\n.mentions__mention {\n  background-color: #cee4e5;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readOnly": `style_readOnly__H0sUy`,
	"mentions": `style_mentions__3EXr0`,
	"mentions__suggestions__list": `style_mentions__suggestions__list__-qC+0`,
	"mentions__suggestions__item": `style_mentions__suggestions__item__NSwWp`,
	"mentions__suggestions__item--focused": `style_mentions__suggestions__item--focused__T1krB`,
	"mentions__mention": `style_mentions__mention__GAk2c`
};
export default ___CSS_LOADER_EXPORT___;
