export const Sessions = [
    {
        label: "2023 Q4",
        value: 0
    },
    {
        label: "2024 Q1",
        value: 1
    },
    {
        label: "2024 Q2",
        value: 2
    },
    {
        label: "2024 Q3",
        value: 3
    }
]
export const reportType=[
    {
        label: "Key Result",
        value: "key_results"
    },
    {
        label: "Objective",
        value: "objectives"
    },
    {
        label:"Goal",
        value:"goal"
    },
    {
        label:"Team",
        value:"teams"
    }

]


export const Units = [{
    label: "Number",
    value: "count "

}, {
    label: "Percentage(%)",
    value: "%"

},{
    label: "US Dollar ($)",
    value: "$"

},
{
    label: "British pound(£)",
    value: "£"
},
{

    label: "Euro(€)",
    value: "€"
},
];
export const confidenceLevels = [
    {
        label: "Low",
        value: 0,
        color: "bg-orange-100",
        hoverColor: "hover:bg-orange-300",
        activeColor: "bg-orange-400"
    },
    {
        label: "Medium",
        value: 1,
        color: "bg-blue-200",
        hoverColor: "hover:bg-blue-400",
        activeColor: "bg-blue-500"
    },
    {
        label: "High",
        value: 2,
        color: "bg-green-200",
        hoverColor: "hover:bg-green-500",
        // textColor:"text-white",
        activeColor: "bg-green-600"
    }
]